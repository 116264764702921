.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  margin: 0 1rem;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: var(--text-secondary);
}

.compensation-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border);
  border-radius: 4px;
  background: var(--surface);
  color: var(--text-primary);
}

.compensation-input:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 1px var(--accent);
}

.compensation-range {
  background: var(--surface-alt);
  border: 1px solid var(--border);
  border-radius: 6px;
  padding: 1rem;
}

.compensation-range h3 {
  margin: 0 0 1rem 0;
  font-size: 1rem;
  color: var(--text-secondary);
}

.range-details {
  display: grid;
  gap: 1rem;
}

.range-details > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.range-details label {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.range-details span {
  color: var(--text-primary);
  font-weight: 500;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}

.compensation-action-button.primary {
  background: var(--accent);
  border-color: var(--accent);
  color: white;
}

.compensation-action-button.primary:hover {
  background: var(--accent-hover);
  border-color: var(--accent-hover);
}

/* Combobox Styles */
.combobox-container {
  position: relative;
  width: 100%;
}

.combobox-input {
  width: 100%;
  height: 38px;
  padding: 0 12px;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 4px;
  color: var(--text-primary);
  font-size: 0.875rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.combobox-input:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 1px var(--accent);
}

.combobox-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 30;
}

.combobox-option {
  padding: 8px 12px;
  color: var(--text-primary);
  font-size: 0.875rem;
  cursor: pointer;
  transition: background 0.2s ease;
}

.combobox-option:hover {
  background: var(--surface-hover);
}

.combobox-input::placeholder {
  color: var(--text-secondary);
}

/* Error Message */
.error-message {
  color: var(--error);
  font-size: 0.875rem;
  margin-top: 0.5rem;
} 