/* Theme Variables */
:root {
    /* Base colors */
    --background: #17181d;
    --surface: #1d1e23;
    --surface-hover: #2b2d33;
    --border: #363940;
    
    /* Text colors */
    --text-primary: #fff;
    --text-secondary: #a1a1aa;
    
    /* Accent colors */
    --accent: #5e6ad2;
    --accent-hover: #6e7ae2;
    --accent-light: rgba(94, 106, 210, 0.1);
    
    /* Status colors */
    --success: #2dd4bf;
    --success-light: rgba(45, 212, 191, 0.1);
    --warning: #f59e0b;
    --warning-light: rgba(245, 158, 11, 0.1);
    --error: #f43f5e;
    --error-light: rgba(244, 63, 94, 0.1);
    --success-dark: #20a99a;  /* Add this line */
}

/* Base Layout */
.scenario-planning {
    padding: 2rem;
    color: var(--text-primary);
}

.form-group textarea {
    width: 100%;
    min-height: 80px;
    padding: 0.75rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 4px;
    color: var(--text-primary);
    font-family: inherit;  /* This will use the same font as the rest of your app */
    font-size: 0.875rem;
    resize: vertical;
    margin-top: 0.5rem;
}

.form-group textarea:hover {
    border-color: var(--accent);
}

.form-group textarea:focus {
    outline: none;
    border-color: var(--accent);
    box-shadow: 0 0 0 1px var(--accent);
}

.form-group textarea::placeholder {
    color: var(--text-secondary);
}

.form-group label {
    color: var(--text-secondary);
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    display: block;
}
/* Summary Section */
.summary-block {
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 2rem;
}

.summary-block h2 {
    color: var(--text-primary);
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 500;
}

.summary-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));	
    gap: 2rem;
    margin-bottom: 2rem;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 6px;
}

.stat-item label {
    font-size: 0.9rem;
    color: var(--text-secondary);
    margin-bottom: 0.5rem;
}

.stat-item span {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
}

/* Charts Container */
.scenario-planning .charts-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 1rem;
    width: calc(100% - 32px);
    margin-right: 20px;
}

.scenario-planning .chart-wrapper {
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 16px;
    height: auto;
    min-height: 200px;
    margin-bottom: 0;
    width: 100%;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
}

.scenario-planning .chart-wrapper h3 {
    margin: 0 0 8px 0;
    font-size: 0.875rem;
    color: var(--text-secondary);
    text-align: left;
    padding-bottom: 0;
}

.scenario-planning .monthly-hires,
.scenario-planning .cumulative-hires,
.scenario-planning .cumulative-cost {
    display: flex;
    flex-direction: column;
}

/* Ensure charts take up full width */
.scenario-planning .recharts-responsive-container {
    width: 100% !important;
    height: 180px !important;
}

/* Adjust chart margins */
.scenario-planning .recharts-wrapper {
    margin: 0 auto;
}

.hire-edit-wrapper {
    background: var(--surface-hover);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Chart Filters */
.chart-filters {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.filter-group {
    min-width: 200px;
}

.filter-select {
    width: 100%;
    padding: 0.5rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 6px;
    color: var(--text-primary);
    cursor: pointer;
    font-size: 0.875rem;
}

/* Chart Customization */
.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
    stroke: var(--border);
    stroke-opacity: 0.2;
}

.recharts-text {
    fill: var(--text-secondary);
    font-size: 12px;
}

.recharts-tooltip-wrapper {
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.recharts-wrapper {
    overflow: visible;
}

/* Organization Sections */
.org-sections {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.org-section {
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
    overflow: hidden;
}

.org-header {
    padding: 0.75rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background: var(--surface);
    transition: background-color 0.2s;
}

.org-header:hover {
    background: var(--surface-hover);
}

.org-title {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.org-title h3 {
    margin: 0;
    font-size: 1rem;
    color: var(--text-primary);
}

.org-quick-stats {
    display: flex;
    gap: 2rem;
    color: var(--text-secondary);
}

.org-content {
    border-top: 1px solid var(--border);
}

.org-content.expanded {
    padding: 1.25rem;
}

/* Form Elements */

.form-row input,
.form-row select,
.hire-edit-form input,
.hire-edit-form select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--border);
    border-radius: 4px;
    background: var(--surface);
    color: var(--text-primary);
    font-size: 0.875rem;
    height: 32px;
}

.form-row input:hover,
.form-row select:hover,
.hire-edit-form input:hover,
.hire-edit-form select:hover {
    border-color: var(--accent);
}

.form-row input:focus,
.form-row select:focus,
.hire-edit-form input:focus,
.hire-edit-form select:focus {
    outline: none;
    border-color: var(--accent);
    box-shadow: 0 0 0 1px var(--accent);
}

/* Placeholder styling */
.form-group input::placeholder,
.form-row input::placeholder,
.hire-edit-form input::placeholder {
    color: var(--text-secondary);
}

/* Date Controls */
.date-selectors {
    display: flex;
    gap: 0.5rem;
}

.date-selectors select {
    flex: 1;
    min-width: 100px;
}

input[type="date"] {
    padding: 0.75rem;
    border: 1px solid var(--border);
    border-radius: 4px;
    background: var(--surface);
    color: var(--text-primary);
}

/* Select Elements */
select {
    padding: 0.75rem;
    border: 1px solid var(--border);
    border-radius: 4px;
    background: var(--surface);
    color: var(--text-primary);
    cursor: pointer;
}

select:hover {
    border-color: var(--accent);
}

.manager-select {
    min-width: 200px;
}

.manager-select optgroup {
    color: var(--text-secondary);
    font-weight: normal;
}

.manager-select option {
    padding: 0.5rem;
}

/* Priority Indicators */
.priority {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    text-align: center;
    font-size: 0.9rem;
}

.priority-dot {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
  }

.priority-dot.high {
    background: var(--error-light);
    color: var(--error);
}

.priority-dot.medium {
    background: var(--warning-light);
    color: var(--warning);
}

.priority-dot.low {
    background: var(--success-light);
    color: var(--success);
}

.hire-summary-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 0.75rem;
    transition: all 0.2s;
  }
  
  .hire-summary-item:hover {
    border-color: var(--accent);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .hire-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  
  .hire-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .role-title {
    font-weight: 500;
    color: var(--text-primary);
    font-size: 1rem;
  }
  
  .hire-details {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--text-secondary);
    font-size: 0.875rem;
    flex-wrap: wrap;
  }
  
  .hire-actions {
    display: flex;
    gap: 0.5rem;
    margin-left: 1rem;
    flex-shrink: 0;  /* Prevent buttons from shrinking */
  }
  
  .edit-hire,
  .remove-hire {
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
    border: none;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap;  /* Prevent button text from wrapping */
  }
  
  .edit-hire {
    background: var(--accent-light);
    color: var(--accent);
  }
  
  .edit-hire:hover {
    background: var(--accent);
    color: white;
  }
  
  .remove-hire {
    background: var(--error-light);
    color: var(--error);
  }
  
  .remove-hire:hover {
    background: var(--error);
    color: white;
  }

/* Buttons and Actions */
.create-scenario-btn {
    background: var(--accent);
    color: var(--text-primary);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
    font-weight: 500;
    width: 100%;
}

.create-scenario-btn:hover {
    background: var(--accent-hover);
}

.remove-hire.success {
    background: var(--success);
    color: white;
}

.expand-button {
    background: none;
    border: none;
    font-size: 1.2rem;
    color: var(--text-secondary);
    cursor: pointer;
    transition: transform 0.2s;
}

.expand-button.expanded {
    transform: rotate(180deg);
}

/* Form Actions */
.scenario-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 2rem;
}

.cancel-button {
    padding: 0.75rem 1.5rem;
    border: 1px solid var(--border);
    border-radius: 4px;
    background: var(--surface);
    color: var(--text-primary);
    cursor: pointer;
}

.save-scenario {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    background: var(--accent);
    color: var(--text-primary);
    cursor: pointer;
}

.save-scenario:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Hire Form and Edit Styling */
.hire-form {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.25rem;
}

.hire-edit-form {
    width: 100%;
    padding: 0.25rem;
    background: var(--surface);
    border-radius: 4px;
    margin-top: 0.5rem;
}

.hire-edit-form .form-row {
    position: relative;
    padding-bottom: 10px;
}

.hire-edit-form .form-row .remove-hire.success {
    position: absolute;
    bottom: 0;
    right: 0;
}

.edit-actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    margin-top: 0.5rem;
}

.save-edit-btn {
    padding: 0.5rem 1rem;
    background: var(--success-light);
    color: var(--success);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.save-edit-btn:hover {
    background: var(--success);
    color: white;
}

.cancel-edit-btn {
    padding: 0.5rem 1rem;
    background: var(--error-light);
    color: var(--error);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-edit-btn:hover {
    background: var(--error);
    color: white;
}

/* Scenario Type Sections */
.scenario-type-section {
    margin-bottom: 1rem;
    border: 1px solid var(--border);
    border-radius: 8px;
    overflow: hidden;
    background: var(--surface);
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
.scenario-type-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    background: var(--surface);
    border-radius: 8px;
    transition: background-color 0.2s;
  }
  
  .scenario-type-header:hover {
    background: var(--surface-hover);
  }
  
  .scenario-type-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .scenario-type-info h4 {
    margin: 0;
    font-size: 1.1rem;
  }
  
  .scenario-stats {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .stat-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.75rem;
    border-radius: 16px;
    font-size: 0.875rem;
    color: var(--text-secondary);
    background: var(--surface-hover);
  }
.scenario-type-header:hover {
    background: var(--surface-hover);
}

.scenario-type-header h4 {
    margin: 0;
    font-size: 1.1rem;
    color: var(--text-primary);
}

.scenario-content {
    padding: 1.5rem;
    border-top: 1px solid var(--border);
}

/* Simple color styling for the scenario type options */
.filter-select option[value="Base Case"] {
    color: var(--accent);
}

.filter-select option[value="Bull Case"] {
    color: var(--success);
}

.filter-select option[value="Bear Case"] {
    color: var(--error);
}

.filter-select option[value="Exceptions"] {
    color: var(--warning);
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .scenario-planning .charts-container {
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .scenario-planning .chart-wrapper {
        padding: 16px;
        min-height: 180px;
    }

    .summary-stats {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .scenario-planning {
        padding: 1rem;
    }

    .planning-toggle,
    .header-actions,
    .summary-stats,
    .summary-block,
    .bulk-approval-button {
        display: none !important;
    }

    .org-header .org-quick-stats {
        display: none !important;
    }

    .hire-summary-item {
        padding: 0.75rem;
    }

    .hire-content {
        flex-direction: column;
        gap: 0.5rem;
    }

    .hire-details,
    .hire-info > *:not(.role-title):not(.hire-actions) {
        display: none;
    }

    .hire-info {
        flex-direction: column;
    }

    .role-title {
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
        display: block !important;
    }

    .hire-actions {
        margin-top: 0.5rem;
        display: flex !important;
        gap: 0.25rem;
    }

    .quarter-header {
        flex-direction: column;
        gap: 0.5rem;
    }

    .quarter-divider {
        width: 100%;
        text-align: left;
    }

    /* Compact button styles for mobile */
    .hire-actions button {
        padding: 4px 8px;
        font-size: 0.75rem;
        min-width: 0;
        flex: 1;
    }

    /* Hide original text and show abbreviated versions */
    .hire-actions .approval-request-button {
        font-size: 0;
    }
    .hire-actions .approval-request-button::after {
        content: 'Aprv';
        font-size: 0.75rem;
    }
    .hire-actions .approval-request-button[data-status="pending"]::after {
        content: 'Rvrt';
    }

    .hire-actions .edit-hire {
        font-size: 0;
    }
    .hire-actions .edit-hire::after {
        content: 'Edit';
        font-size: 0.75rem;
    }

    .hire-actions .remove-hire {
        font-size: 0;
    }
    .hire-actions .remove-hire::after {
        content: 'Dlt';
        font-size: 0.75rem;
    }
}

@media (max-width: 480px) {
    .scenario-planning {
        padding: 0.5rem;
    }

    .summary-block,
    .chart-wrapper,
    .scenario-card {
        padding: 1rem;
    }

    .scenarios-list {
        grid-template-columns: 1fr;
    }

    .stat-item span {
        font-size: 1.25rem;
    }

    .chart-wrapper h3 {
        font-size: 1rem;
    }
}

/* Print Styles */
@media print {
    .scenario-planning {
        padding: 0;
    }

    .create-scenario-btn,
    .edit-scenario-btn,
    .remove-hire,
    .scenario-actions {
        display: none;
    }

    .chart-wrapper {
        break-inside: avoid;
        page-break-inside: avoid;
    }

    .scenario-card {
        break-inside: avoid;
        page-break-inside: avoid;
        border: 1px solid #ddd;
    }
}


/* Form Grid Layout */
.hire-form .form-row,
.hire-edit-form .form-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 0.25rem; /* Reduced from 0.5rem */
    margin-bottom: 0.25rem; /* Reduced from 0.5rem */
}

/* First row */
.hire-form .title-field {
    grid-column: 1 / 3; /* Span 2 columns */
    grid-row: 1;
}

.hire-form .jobid-field {
    grid-column: 3;
    grid-row: 1;
}

/* Second row */
.hire-form .combobox-wrapper {
    grid-column: 1;
    grid-row: 2;
}

.hire-form .function-field {
    grid-column: 2;
    grid-row: 2;
}

.hire-form .level-field {
    grid-column: 3;
    grid-row: 2;
}

/* Third row */
.hire-form .manager-field {
    grid-column: 1;
    grid-row: 3;
}

.hire-form .month-field {
    grid-column: 2;
    grid-row: 3;
}

.hire-form .year-field,
.hire-form .priority-field {
    grid-column: 3;
    grid-row: 3;
}

/* Common field styles */
.hire-form input,
.hire-form select,
.hire-form .combobox-wrapper input {
    width: 100%;
    height: 36px;
    padding: 0 12px;
    border: 1px solid var(--border);
    border-radius: 4px;
    background: var(--surface);
    color: var(--text-primary);
    font-size: 0.875rem;
}

/* Hover and focus states */
.hire-form input:hover,
.hire-form select:hover,
.hire-form .combobox-wrapper input:hover {
    border-color: var(--accent);
}

.hire-form input:focus,
.hire-form select:focus,
.hire-form .combobox-wrapper input:focus {
    outline: none;
    border-color: var(--accent);
    box-shadow: 0 0 0 1px var(--accent);
}

/* Exception reason field (if present) */
.hire-form .exception-reason-field {
    grid-column: 1 / -1;
    grid-row: 4;
    width: 100%;
    min-height: 60px;
    padding: 8px 12px;
    margin-top: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .hire-form .form-row {
        grid-template-columns: repeat(2, 1fr);
    }

    .hire-form .title-field {
        grid-column: 1 / 3;
    }

    .hire-form .jobid-field {
        grid-column: 1;
        grid-row: 2;
    }

    .hire-form .combobox-wrapper {
        grid-column: 2;
        grid-row: 2;
    }

    .hire-form .function-field {
        grid-column: 1;
        grid-row: 3;
    }

    .hire-form .level-field {
        grid-column: 2;
        grid-row: 3;
    }

    .hire-form .manager-field {
        grid-column: 1;
        grid-row: 4;
    }

    .hire-form .month-field {
        grid-column: 2;
        grid-row: 4;
    }

    .hire-form .year-field,
    .hire-form .priority-field {
        grid-column: 1 / 3;
        grid-row: 5;
    }
}

@media (max-width: 768px) {
    .hire-form .form-row {
        grid-template-columns: 1fr;
    }

    .hire-form .title-field,
    .hire-form .jobid-field,
    .hire-form .combobox-wrapper,
    .hire-form .function-field,
    .hire-form .level-field,
    .hire-form .manager-field,
    .hire-form .month-field,
    .hire-form .year-field,
    .hire-form .priority-field {
        grid-column: 1;
    }

    .hire-form .title-field { grid-row: 1; }
    .hire-form .jobid-field { grid-row: 2; }
    .hire-form .combobox-wrapper { grid-row: 3; }
    .hire-form .function-field { grid-row: 4; }
    .hire-form .level-field { grid-row: 5; }
    .hire-form .manager-field { grid-row: 6; }
    .hire-form .month-field { grid-row: 7; }
    .hire-form .year-field,
    .hire-form .priority-field { grid-row: 8; }
}

.add-hire-button,
.update-hire-button {
    background: var(--success-light);
    color: var(--success);
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-left: auto; /* This pushes the button to the right */
    display: block; /* Makes margin-left: auto work */
    width: auto; /* Override the full width */
}

.add-hire-button:hover,
.update-hire-button:hover {
    background: var(--success);
    color: white;
}

/* Add some spacing above the buttons */
.hire-form,
.hire-edit-form {
    display: flex;
    flex-direction: column;
    gap: 0.25rem; /* Reduced from 0.5rem */
}
.org-quick-stats {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
  
  .case-stat {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  
  .case-label {
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
  
  .case-value {
    color: var(--text-primary);
    font-weight: 500;
  }

  .completed-hire {
    color: var(--success);
    font-weight: 500;
  }
  
  .hire-info .location {
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
  
  .hire-details {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
  }

.quarter-group {
  margin-bottom: 1.5rem;
}

.quarter-divider {
  color: var(--text-secondary);
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--border);
}

/* Update existing hire-summary-item margin */
.hire-summary-item {
  margin-bottom: 0.75rem;
}

.hire-summary-item:last-child {
  margin-bottom: 0;
}

/* Scenario type styling */
.scenario-type-section {
  border-left: 3px solid transparent;
}

.scenario-type-section[data-type="Base Case"] {
  border-left-color: var(--accent);
}

.scenario-type-section[data-type="Bull Case"] {
  border-left-color: var(--success);
}

.scenario-type-section[data-type="Bear Case"] {
  border-left-color: var(--error);
}

.scenario-type-section[data-type="Exceptions"] {
  border-left-color: var(--warning);
}

/* Exception reason styling */
.exception-reason-field {
  grid-column: span 4;  /* Instead of 1 / -1, span exactly 4 columns */
  width: 97.8%;
  height: 36px;
  padding: 4px 12px;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 4px;
  color: var(--text-primary);
  font-size: 0.875rem;
  resize: none;
  margin-top: 0;
  line-height: 20px;
}

.exception-reason {
  color: var(--warning);
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Style the scenario type dropdown */
.filter-select option[value="Exceptions"] {
  background-color: var(--warning-light);
  color: var(--warning);
  font-weight: 500;
}

.filter-select option[value="Base Case"],
.filter-select option[value="Bull Case"],
.filter-select option[value="Bear Case"] {
  font-weight: 500;
}

.filter-select option[value="Base Case"] {
  background-color: var(--accent-light);
  color: var(--accent);
}

.filter-select option[value="Bull Case"] {
  background-color: var(--success-light);
  color: var(--success);
}

.filter-select option[value="Bear Case"] {
  background-color: var(--error-light);
  color: var(--error);
}

/* Style the scenario type dropdown options */
.filter-select option[value="Base Case"] {
  color: var(--accent);
}

.filter-select option[value="Bull Case"] {
  color: var(--success);
}

.filter-select option[value="Bear Case"] {
  color: var(--error);
}

.filter-select option[value="Exceptions"] {
  color: var(--warning);
}

/* Scenario filter styling */
.scenario-filter {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23A1A1AA%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.4-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
  padding-right: 2rem;
}

.scenario-filter option {
  padding: 8px;
  margin: 4px;
}

.scenario-filter option.base-case {
  border-left: 3px solid var(--accent);
}

.scenario-filter option.bull-case {
  border-left: 3px solid var(--success);
}

.scenario-filter option.bear-case {
  border-left: 3px solid var(--error);
}

.scenario-filter option.exceptions {
  border-left: 3px solid var(--warning);
  margin-top: 8px;
  border-top: 1px solid var(--border);
}

/* Style the select itself based on the current value */
.scenario-filter[value="Base Case"] {
  border-color: var(--accent);
  color: var(--accent);
}

.scenario-filter[value="Bull Case"] {
  border-color: var(--success);
  color: var(--success);
}

.scenario-filter[value="Bear Case"] {
  border-color: var(--error);
  color: var(--error);
}

.scenario-filter[value="Exceptions"] {
  border-color: var(--warning);
  color: var(--warning);
}

/* Update the scenario type header styling */
.scenario-type-info h4 {
  margin: 0;
  font-size: 1.1rem;
}

/* Style each case type differently */
.scenario-type-section[data-type="Base Case"] h4 {
  color: var(--accent);
}

.scenario-type-section[data-type="Bull Case"] h4 {
  color: var(--success);
}

.scenario-type-section[data-type="Bear Case"] h4 {
  color: var(--error);
}

.scenario-type-section[data-type="Exceptions"] h4 {
  color: var(--warning);
}

/* Remove any duplicate or conflicting styles and update the dropdown styling */
.filter-select option {
  padding: 8px;
}

.filter-select option[value="Base Case"],
.filter-select option[value="Bull Case"],
.filter-select option[value="Bear Case"],
.filter-select option[value="Exceptions"] {
  font-weight: 500;
  padding: 8px;
  color: var(--text-primary);
}

.filter-select option[value="Base Case"] {
  color: var(--accent);
}

.filter-select option[value="Bull Case"] {
  color: var(--success);
}

.filter-select option[value="Bear Case"] {
  color: var(--error);
}

.filter-select option[value="Exceptions"] {
  color: var(--warning);
}

/* Planning toggle styling */
.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.header-row h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
}

.planning-toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.status-label {
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
}

.status-label.open {
  background: var(--success-light);
  color: var(--success);
}

.status-label.closed {
  background: var(--error-light);
  color: var(--error);
}

.toggle-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.toggle-button.open {
  background: var(--error-light);
  color: var(--error);
}

.toggle-button.open:hover {
  background: var(--error);
  color: white;
}

.toggle-button.closed {
  background: var(--success-light);
  color: var(--success);
}

.toggle-button.closed:hover {
  background: var(--success);
  color: white;
}

/* Disabled state for buttons */
.hire-actions button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

/* Visual indicator for frozen cases */
.scenario-type-section[data-type="Base Case"],
.scenario-type-section[data-type="Bull Case"],
.scenario-type-section[data-type="Bear Case"] {
  opacity: 0.8;
  position: relative;
}

.scenario-type-section[data-type="Base Case"]:not(.exceptions)::after,
.scenario-type-section[data-type="Bull Case"]:not(.exceptions)::after,
.scenario-type-section[data-type="Bear Case"]:not(.exceptions)::after {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.planning-closed .scenario-type-section[data-type="Base Case"]:not(.exceptions)::after,
.planning-closed .scenario-type-section[data-type="Bull Case"]:not(.exceptions)::after,
.planning-closed .scenario-type-section[data-type="Bear Case"]:not(.exceptions)::after {
  opacity: 1;
}

/* Switch styling */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin-left: 12px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--error);
  transition: .3s;
  border-radius: 26px;
  border: 2px solid var(--error);
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .3s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--success);
  border-color: var(--success);
}

input:checked + .slider:before {
  transform: translateX(24px);
}

.status-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-primary);
}

.status-label.open {
  color: var(--success);
}

.status-label.closed {
  color: var(--error);
}

/* Remove old toggle button styles */
.toggle-button {
  display: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.scenario-role-modal {
  background: #17181c;
  border-radius: 8px;
  padding: 24px;
  width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.modal-header h2 {
  margin: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.close-button {
  background: none;
  border: none;
  color: #71717a;
  cursor: pointer;
  padding: 8px;
  margin: -8px;
}

.form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Specific field widths */
.form-group:nth-child(1),
.form-group:nth-child(2) {
  grid-column: span 1;
}

.form-group:nth-child(3),
.form-group:nth-child(4) {
  grid-column: span 1;
}

.form-group:nth-child(5),
.form-group:nth-child(6) {
  grid-column: span 1;
}

.form-group:nth-child(7),
.form-group:nth-child(8) {
  grid-column: span 1;
}

.form-group label {
  color: #a1a1aa;
  font-size: 14px;
  font-weight: 500;
}

.form-group input,
.form-group select {
  width: 100%;
  height: 36px;
  padding: 0 12px;
  background: #1d1e23;
  border: 1px solid #27272a;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  box-sizing: border-box;
}

.form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2.5 4.5L6 8L9.5 4.5' stroke='%23a1a1aa' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 32px;
}

.form-group input:hover,
.form-group select:hover {
  border-color: #3b82f6;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #27272a;
}

.cancel-button {
  padding: 8px 16px;
  background: transparent;
  border: 1px solid #27272a;
  border-radius: 4px;
  color: #a1a1aa;
  font-size: 14px;
  cursor: pointer;
  min-width: 100px;
}

.save-button {
  padding: 8px 16px;
  background: #3b82f6;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  min-width: 100px;
}

.cancel-button:hover {
  background: #27272a;
  color: #fff;
}

.save-button:hover {
  background: #2563eb;
}

/* Remove priority dot from scenario header */
.scenario-type-section .priority-dot {
  display: none;
}

/* Only show priority dots in hire items */
.hire-summary-item .priority-dot {
  display: inline-flex;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.priority-dot.high {
  background: var(--error-light);
  color: var(--error);
}

.priority-dot.medium {
  background: var(--warning-light);
  color: var(--warning);
}

.priority-dot.low {
  background: var(--success-light);
  color: var(--success);
}

@media (max-width: 840px) {
  .scenario-role-modal {
    width: 95%;
    padding: 20px;
  }
  
  .form-container {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .form-group:nth-child(n) {
    grid-column: 1;
  }
}

@media (max-width: 480px) {
  .form-actions {
    flex-direction: column-reverse;
  }

  .cancel-button,
  .save-button {
    width: 100%;
  }
}

.approval-request-button {
  height: 32px;
  padding: 0 12px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
}

/* Default state - Send for Approval */
.approval-request-button {
    background: var(--success-light);
    color: var(--success);
}

.approval-request-button:hover:not(:disabled) {
    background: var(--success);
    color: white;
}

/* Pending state */
.approval-request-button[data-status="pending"] {
    background: var(--warning-light);
    color: var(--warning);
}

.approval-request-button[data-status="pending"]:hover:not(:disabled) {
    background: var(--warning);
    color: white;
}

/* Approved state */
.approval-request-button[data-status="approved"] {
    background: var(--error-light);
    color: var(--error);
}

.approval-request-button[data-status="approved"]:hover:not(:disabled) {
    background: var(--error);
    color: white;
}

/* Rejected state */
.approval-request-button[data-status="rejected"] {
    background: var(--error-light);
    color: var(--error);
}

.approval-request-button[data-status="rejected"]:hover:not(:disabled) {
    background: var(--error);
    color: white;
}

/* Disabled state */
.approval-request-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Edit button when approval is in progress */
.edit-hire:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: var(--surface-hover);
}

/* Status badge */
.approval-status-badge {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.85em;
    font-weight: 500;
    margin-left: 8px;
}

.approval-status-badge.pending {
    background: var(--warning-light);
    color: var(--warning);
}

.approval-status-badge.approved {
    background: var(--success-light);
    color: var(--success);
}

.approval-status-badge.rejected {
    background: var(--error-light);
    color: var(--error);
}

.view-toggle-container {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
  margin-top: 24px;
}

.view-toggle {
  background-color: #2a2b32;
  border: 1px solid var(--border);
  color: var(--text-primary);
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.view-toggle:hover {
  background-color: #3a3b42;
}

.org-chart-header-wrapper {
  background: #1d1e23;
  border: 1px solid var(--border);
  border-radius: 6px;
  margin: 24px 0;
}

.org-chart-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.collapse-toggle {
  background: none;
  border: none;
  color: var(--text-primary);
  font-size: 14px;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.collapse-toggle:hover {
  background: rgba(255, 255, 255, 0.1);
}

.org-chart-header-content h2 {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0 0 4px 0;
}

.org-chart-subtitle {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0;
}

.org-chart-content {
  background: #1d1e23;
  border: 1px solid var(--border);
  border-radius: 6px;
  margin-top: 2px;
  padding: 24px;
}

.org-chart-header-content .view-toggle {
  background-color: #2a2b32;
  border: 1px solid var(--border);
  color: var(--text-primary);
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  min-width: 120px;
}

.org-chart-header-content .view-toggle:hover {
  background-color: #3a3b42;
}

.collapsible-section {
  margin-top: 16px;
  margin-bottom: 24px;
}

.collapsible-header {
  background: #1d1e23;
  border: 1px solid var(--border);
  border-radius: 6px;
  padding: 12px 16px;
  cursor: pointer;
  user-select: none;
}

.collapsible-header:hover {
  background: #22232a;
}

.collapsible-header .header-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.collapsible-header h3 {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  color: var(--text-primary);
}

.collapse-toggle {
  color: var(--text-secondary);
  font-size: 12px;
  width: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.collapsible-content {
  background: #1d1e23;
  border: 1px solid var(--border);
  border-radius: 6px;
  margin-top: 2px;
  padding: 24px;
}

.header-actions {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.import-export-actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.import-button,
.export-button,
.template-button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: 1px solid var(--border);
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    background: var(--surface);
    color: var(--text-primary);
}

.import-button:hover,
.export-button:hover,
.template-button:hover {
    background: var(--surface-hover);
    border-color: var(--accent);
}

.import-button {
    background: var(--accent-light);
    color: var(--accent);
}

.import-button:hover {
    background: var(--accent);
    color: white;
    border-color: var(--accent);
}

.export-button {
    background: var(--success-light);
    color: var(--success);
}

.export-button:hover {
    background: var(--success);
    color: white;
    border-color: var(--success);
}

.template-button {
    background: var(--surface-hover);
    color: var(--text-secondary);
}

.template-button:hover {
    background: var(--surface);
    color: var(--text-primary);
    border-color: var(--accent);
}

.export-excel-button {
    padding: 0.5rem 1rem;
    background: var(--success);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
}

.export-excel-button:hover {
    background: var(--success-dark);
    transform: translateY(-1px);
}

.export-excel-button:active {
    transform: translateY(0);
}

/* Organization Chart Section */
.org-chart-section {
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
    margin-bottom: 2rem;
    overflow: hidden;
}

.org-chart-section:not(:has(.org-chart-section-content)) {
    margin-bottom: 0;
    border-radius: 6px;
    min-height: unset;
    height: auto;
    max-height: 48px; /* Match header height */
}

.org-chart-section:not(:has(.org-chart-section-content)) .section-header {
    border-bottom: none;
}

.org-chart-section-content {
    min-height: 600px;
}

.section-header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid var(--border);
    background: var(--surface);
    min-height: 48px;
}

.section-header h3 {
    margin: 0;
    font-size: 1rem;
    color: var(--text-primary);
    text-align: center;
    white-space: nowrap;
    grid-column: 2;
}

.toggle-visibility {
    padding: 0.25rem 0.75rem;
    background: var(--surface-hover);
    border: 1px solid var(--border);
    border-radius: 4px;
    color: var(--text-secondary);
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
    height: 28px;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1;
}

.chart-controls {
    grid-column: 3;
}

.toggle-visibility:hover {
    background: var(--surface);
    color: var(--text-primary);
    border-color: var(--accent);
}

.filter-select {
    min-width: 160px;
    max-width: 200px;
    padding: 0.25rem 0.75rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 4px;
    color: var(--text-primary);
    font-size: 0.875rem;
    cursor: pointer;
    height: 28px;
}

.unallocated-section {
    margin-bottom: 1rem;
    border: 1px solid var(--border);
    border-radius: 8px;
    background-color: var(--surface);
}

.unallocated-section .org-header {
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.unallocated-section .org-content {
    padding: 1rem;
    border-top: 1px solid var(--border);
}

.unallocated-role-card {
    background-color: var(--surface-hover);
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 0.5rem;
}

.unallocated-role-card .role-info h5 {
    margin: 0 0 0.5rem 0;
    color: var(--text-primary);
}

.unallocated-role-card .role-details {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    color: var(--text-secondary);
    font-size: 0.875rem;
}

.unallocated-role-card .role-details span {
    background-color: var(--surface);
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.approval-status {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.9em;
    font-weight: 500;
}

.approval-status.pending {
    background-color: #ffd700;
    color: #000;
}

.approval-status.approved {
    background-color: #4caf50;
    color: white;
}

.approval-status.rejected {
    background-color: #f44336;
    color: white;
}

.pending-badge {
    background-color: #ffd700;
    color: #000;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.9em;
    font-weight: 500;
}

.approval-button {
    background-color: #2196f3;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.2s;
}

.approval-button:hover {
    background-color: #1976d2;
}

.delete-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    margin-left: 8px;
    transition: background-color 0.2s;
}

.delete-button:hover {
    background-color: #d32f2f;
}

.quarter-header {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quarter-divider {
    font-weight: 500;
    font-size: 14px;
}

.bulk-approval-button {
    height: 28px;
    padding: 0 12px;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease;
    background: var(--surface-hover);
    border: none;
    color: var(--success);
}

.bulk-approval-button:hover:not(:disabled) {
    background: var(--success);
    color: white;
}

.bulk-approval-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media (max-width: 1024px) {
    /* Hide org chart related components */
    .org-chart-container,
    .org-chart-header,
    .org-chart-header-wrapper,
    .org-chart-content,
    .org-chart-section,
    .org-chart-section-content,
    .zoom-controls,
    .chart-wrapper {
        display: none !important;
    }

    .hire-form .form-row,
    .hire-edit-form .form-row {
        grid-template-columns: 1fr;
    }

    .date-fields {
        flex-direction: column;
    }

    .month-field,
    .year-field {
        width: 100%;
    }
}

.hire-form .form-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.hire-form .title-field,
.hire-form .team-field,
.hire-form .jobid-field,
.hire-form .function-field,
.hire-form .level-field,
.hire-form .manager-field,
.hire-form .month-field,
.hire-form .year-field,
.hire-form .priority-field {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    flex: 1;
}

.hire-form .combobox-wrapper {
    position: relative;
    flex: 1;
}

.hire-form .combobox-wrapper input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.hire-form .combobox-wrapper datalist {
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
}

