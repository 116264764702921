.mentions-dropdown {
  position: absolute;
  background: var(--surface, #1d1e23);
  border: 1px solid var(--border, #363940);
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-height: 200px;
  overflow-y: auto;
  width: calc(100% - 2rem);
  z-index: 9999;
  left: 1rem;
  bottom: calc(100% + 4px); /* Position above the textarea */
}

.mention-item {
  padding: 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 0.2s ease;
  border-left: 2px solid transparent;
  background: var(--surface, #1d1e23);
}

.mention-item:hover,
.mention-item.selected {
  background: var(--surface-alt, #2b2d33);
  border-left-color: var(--accent, #5e6ad2);
}

.mention-user-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.mention-name {
  font-weight: 500;
  color: var(--text-primary, #fff);
}

.mention-email {
  font-size: 0.8rem;
  color: var(--text-secondary, #a1a1aa);
}

.mention-no-results {
  padding: 1rem;
  text-align: center;
  color: var(--text-secondary, #a1a1aa);
  font-size: 0.875rem;
  background: var(--surface, #1d1e23);
}

/* Scrollbar styles */
.mentions-dropdown::-webkit-scrollbar {
  width: 6px;
}

.mentions-dropdown::-webkit-scrollbar-track {
  background: var(--surface, #1d1e23);
}

.mentions-dropdown::-webkit-scrollbar-thumb {
  background: var(--border, #363940);
  border-radius: 3px;
}

.mentions-dropdown::-webkit-scrollbar-thumb:hover {
  background: var(--text-tertiary, #71717a);
} 