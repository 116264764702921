.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .ta-team-modal {
    background: var(--surface);
    border-radius: 8px;
    padding: 2rem;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .modal-header h2 {
    margin: 0;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--text-secondary);
  }
  
  .settings-tabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--border);
    padding-bottom: 0.5rem;
  }
  
  .tab-button {
    padding: 0.5rem 1rem;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-secondary);
    font-size: 1rem;
    position: relative;
  }
  
  .tab-button.active {
    color: var(--accent);
    font-weight: 500;
  }
  
  .tab-button.active::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    right: 0;
    height: 2px;
    background: var(--accent);
  }
  
  /* Team Members Section */
  .add-member-form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: var(--surface-alt);
    border-radius: 8px;
    border: 1px solid var(--border);
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group label {
    font-weight: 500;
    color: var(--text-primary);
  }
  
  .form-group input,
  .form-group select {
    height: 38px;
    padding: 0.5rem;
    border: 1px solid var(--border);
    border-radius: 4px;
    background: var(--surface);
    color: var(--text-primary);
    font-size: 0.9rem;
  }
  
  .form-group input:focus,
  .form-group select:focus {
    border-color: var(--accent);
    outline: none;
    box-shadow: 0 0 0 2px var(--accent-light);
  }
  
  .add-member-form .add-button {
    grid-column: 1 / -1;
    padding: 0.75rem;
    height: 38px;
    background: var(--accent);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
  }
  
  .add-member-form .add-button:hover {
    background: var(--accent-dark);
  }
  
  .team-members-list {
    margin-top: 2rem;
  }
  
  .members-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .member-card {
    background: var(--surface-alt);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 1rem;
  }
  
  .member-card.inactive {
    opacity: 0.7;
    background: var(--surface-disabled);
  }
  
  .member-info h4 {
    margin: 0 0 0.5rem 0;
  }
  
  .member-info p {
    margin: 0.25rem 0;
    color: var(--text-secondary);
  }
  
  .member-role {
    font-weight: 500;
    color: var(--accent) !important;
  }
  
  .member-load {
    font-size: 0.875rem;
  }
  
  .member-actions {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
  
  .deactivate-button,
  .activate-button,
  .delete-member-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
    white-space: nowrap;
  }
  
  .deactivate-button {
    background: var(--error-light);
    color: var(--error);
  }
  
  .activate-button {
    background: var(--success-light);
    color: var(--success);
  }
  
  .delete-member-button {
    background: var(--error);
    color: white;
  }
  
  .delete-member-button:hover {
    background: var(--error-dark);
  }
  
  .deactivate-button:hover {
    background: var(--error);
    color: white;
  }
  
  .activate-button:hover {
    background: var(--success);
    color: white;
  }
  
  .no-members {
    text-align: center;
    color: var(--text-secondary);
    padding: 2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .ta-team-modal {
      padding: 1rem;
      width: 95%;
    }
  
    .add-member-form {
      grid-template-columns: 1fr;
      padding: 1rem;
    }
  
    .add-stage-form {
      flex-direction: column;
      align-items: stretch;
    }
  
    .stage-item {
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .stage-actions {
      width: 100%;
      justify-content: flex-end;
    }
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .edit-button {
    padding: 0.5rem 1rem;
    background: var(--accent);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .edit-button:hover {
    background: var(--accent-dark);
  }
  
  .form-section {
    background: var(--surface-alt);
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
  }
  
  .form-section h3 {
    margin: 0 0 1rem 0;
  }
  
  /* Stages Section */
  .stages-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .add-stage-form {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--border);
  }
  
  .add-stage-form .form-group {
    flex: 1;
    margin: 0;
  }
  
  .add-stage-form .add-button {
    padding: 0.5rem 1rem;
    height: 38px;
    margin: 0;
    white-space: nowrap;
    background: var(--accent);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    transition: background-color 0.2s ease;
  }
  
  .add-stage-form .add-button:hover {
    background: var(--accent-dark);
  }
  
  .add-stage-form .add-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--accent-light);
  }
  
  .add-stage-form .add-button:disabled {
    background: var(--surface-disabled);
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .add-stage-form input {
    height: 38px;
    margin: 0;
    padding: 0.5rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 4px;
    color: var(--text-primary);
    font-size: 0.875rem;
  }
  
  .add-stage-form input:focus {
    outline: none;
    border-color: var(--accent);
    box-shadow: 0 0 0 2px var(--accent-light);
  }
  
  .stages-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .stage-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    background: var(--surface-alt);
    border: 1px solid var(--border);
    border-radius: 4px;
  }
  
  .stage-name {
    font-weight: 500;
  }
  
  .stage-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .move-button {
    padding: 0.25rem 0.5rem;
    border: 1px solid var(--border);
    border-radius: 4px;
    background: var(--surface);
    cursor: pointer;
    font-size: 1rem;
    color: var(--text-primary);
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .move-button:hover {
    background: var(--surface-alt);
    border-color: var(--accent);
    color: var(--accent);
  }
  
  .move-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: var(--border);
    color: var(--text-secondary);
  }
  
  .delete-button {
    padding: 0.25rem 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
    background: var(--error-light);
    color: var(--error);
  }
  
  .delete-button:hover {
    background: var(--error);
    color: white;
  }
  
  .stage-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .stage-color-picker {
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: none;
  }
  
  .stage-color-picker::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  
  .stage-color-picker::-webkit-color-swatch {
    border: 1px solid var(--border);
    border-radius: 4px;
  }
  
  .stage-color-picker::-moz-color-swatch {
    border: 1px solid var(--border);
    border-radius: 4px;
  }