.team-viewer {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.team-viewer-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-viewer-header h2 {
  margin: 0;
  color: var(--text-primary);
}

.view-controls {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.control-button {
  padding: 0.375rem 0.75rem;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 4px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
}

.control-button:hover {
  background: var(--surface-hover);
  color: var(--text-primary);
  border-color: var(--accent);
}

.control-button.active {
  background: var(--accent);
  color: white;
  border-color: var(--accent);
}

.scenario-select {
  padding: 0.375rem 0.75rem;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 4px;
  color: var(--text-primary);
  cursor: pointer;
  min-width: 150px;
  font-size: 0.875rem;
}

.scenario-select:hover {
  border-color: var(--accent);
}

.team-stats {
  display: flex;
  gap: 2rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.stat-label {
  color: var(--text-secondary);
  font-size: 0.75rem;
}

.stat-value {
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 500;
}

.organizations-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.organization-group {
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 6px;
  overflow: hidden;
}

.organization-header {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.organization-header:hover {
  background-color: var(--surface-hover);
}

.organization-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.organization-name {
  margin: 0;
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.collapse-icon {
  color: var(--text-secondary);
  font-size: 0.75rem;
  transition: transform 0.2s ease;
}

.organization-stats {
  display: flex;
  gap: 1rem;
}

.organization-stats .stat-label {
  color: var(--text-secondary);
  font-size: 0.75rem;
}

.organization-teams.leadership-team {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
  background: var(--background);
  border-top: 1px solid var(--border);
}

.organization-teams:not(.leadership-team) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
  background: var(--background);
  border-top: 1px solid var(--border);
}

.team-group {
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 6px;
  padding: 1rem;
}

.team-name {
  margin: 0 0 0.75rem 0;
  color: var(--text-primary);
  font-size: 0.875rem;
  font-weight: 500;
}

.team-members {
  display: grid;
  gap: 0.75rem;
}

.team-node {
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 4px;
  padding: 0.75rem;
  transition: all 0.2s ease;
}

.team-node:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-color: var(--accent);
}

.team-node.leaver {
  border: 2px solid #dc3545;
}

.team-node.future-leaver {
  border: 2px solid #ffc107;
}

.team-node.planned-hire {
  border: 2px solid var(--accent);
}

.team-node.planned-hire.completed-hire {
  border: 2px solid #2dd4bf;
}

.team-node-header {
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 0.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.node-level {
  font-size: 0.75rem;
  color: var(--accent);
  font-weight: 500;
  background-color: rgba(var(--accent-rgb), 0.1);
  padding: 0.125rem 0.375rem;
  border-radius: 3px;
  display: inline-block;
}

.team-node-body {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.node-title {
  font-size: 0.8125rem;
  color: var(--text-secondary);
}

.node-function {
  font-size: 0.75rem;
  color: var(--text-secondary);
  margin-top: 0.125rem;
}

.node-contract {
  font-size: 0.75rem;
  color: var(--text-secondary);
}

.node-badge {
  margin-top: 0.375rem;
  padding: 0.125rem 0.375rem;
  font-size: 0.6875rem;
  border-radius: 3px;
  text-align: center;
  background-color: rgba(45, 212, 191, 0.1);
  color: #2dd4bf;
}

.organization-leader {
  font-size: 0.875rem;
  font-weight: normal;
  color: var(--text-secondary);
  margin-left: 1rem;
}

.leader-level {
  font-size: 0.75rem;
  color: var(--accent);
  background-color: rgba(var(--accent-rgb), 0.1);
  padding: 0.125rem 0.375rem;
  border-radius: 3px;
  margin-left: 0.5rem;
  display: inline-block;
}

.toggle-button {
  padding: 0.75rem 1rem;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 6px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
}

.toggle-button:hover {
  background: var(--surface-hover);
  color: var(--text-primary);
  border-color: var(--accent);
}

.toggle-button.active {
  background: var(--accent);
  color: white;
  border-color: var(--accent);
}

.toggle-button.active:hover {
  background: var(--accent-hover);
}

.control-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .team-viewer {
    padding: 1rem;
  }

  .team-viewer-header,
  .team-viewer-controls .show-new-hires,
  .team-viewer-stats {
    display: none !important;
  }

  .team-viewer-content {
    margin-top: 1rem;
  }

  .team-viewer-controls {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .team-viewer-filters {
    width: 100%;
  }
} 