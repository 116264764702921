.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
  overflow-y: auto;
}

.modal-panel {
  width: 98%;
  max-width: 1800px !important;
  background: var(--surface);
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  position: relative;
  z-index: 1001;
  margin: 1rem auto;
  overflow-y: auto;
}

.modal-panel::-webkit-scrollbar {
  width: 8px;
}

.modal-panel::-webkit-scrollbar-track {
  background: var(--surface);
  border-radius: 4px;
}

.modal-panel::-webkit-scrollbar-thumb {
  background: var(--border);
  border-radius: 4px;
}

.modal-panel::-webkit-scrollbar-thumb:hover {
  background: var(--text-secondary);
}

.modal-title {
  position: sticky;
  top: -2rem;
  background: var(--surface);
  padding: 2rem 0 1rem;
  margin: -2rem 0 1.5rem;
  z-index: 2;
  color: var(--text-primary);
  font-size: 1.5rem;
  font-weight: 600;
}

.modal-form {
  width: 100%;
}

.form-grid {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important;
  gap: 2rem;
  width: 100%;
  margin-bottom: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  min-width: 0;
}

.form-group label {
  color: var(--text-secondary);
  font-size: 0.875rem;
  font-weight: 500;
}

.form-group input,
.form-group select {
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
  padding: 0.5rem;
  border: 1px solid var(--border);
  border-radius: 4px;
  background-color: var(--surface-hover);
  color: var(--text-primary);
  font-size: 0.875rem;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 2px rgba(94, 106, 210, 0.25);
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  gap: 12px;
}

.right-buttons {
  display: flex;
  gap: 8px;
}

.modal-actions button {
  height: 36px;
  white-space: nowrap;
}

.delete-button {
  padding: 0.5rem 1rem;
  background: var(--error-light);
  border: none;
  border-radius: 4px;
  color: var(--error);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 36px;
  display: flex;
  align-items: center;
}

.delete-button:hover {
  background: var(--error);
  color: white;
}

.cancel-button {
  padding: 0.5rem 1rem;
  border: 1px solid var(--border);
  border-radius: 4px;
  background-color: transparent;
  color: var(--text-primary);
  cursor: pointer;
}

.cancel-button:hover {
  background-color: var(--surface-hover);
}

.submit-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: var(--accent);
  color: white;
  cursor: pointer;
}

.submit-button:hover {
  background-color: var(--accent-hover);
}

.temporary-leave-button {
  padding: 8px 16px;
  background-color: transparent;
  color: var(--text-primary);
  border: 1px solid var(--border);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.temporary-leave-button:hover {
  background-color: var(--surface-hover);
}

.temporary-leave-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--border);
}

.temporary-leave-section h3 {
  margin-bottom: 16px;
  color: var(--text-primary);
  font-size: 16px;
}

.temporary-leave-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.temporary-leave-submit {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: var(--accent);
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
}

.temporary-leave-submit:hover {
  background-color: var(--accent-hover);
}

@media (max-width: 768px) {
  .modal-overlay {
    padding: 1rem;
  }

  .modal-panel {
    padding: 1.5rem;
    max-height: calc(100vh - 2rem);
  }

  .modal-title {
    top: -1.5rem;
    padding: 1.5rem 0 1rem;
    margin: -1.5rem 0 1rem;
  }

  .modal-actions {
    bottom: -1.5rem;
    margin: 1rem 0 -1.5rem;
    flex-direction: column-reverse;
    gap: 0.5rem;
  }

  .modal-actions button {
    width: 100%;
  }
} 