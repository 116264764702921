.view-content {
  padding: 2rem;
}

.view-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
  gap: 2rem;
}

.view-header h2 {
  margin: 0;
  color: var(--text-primary);
}

.header-actions {
  flex-shrink: 0;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  width: 300px;
  position: relative;
}

.grid-button {
  padding: 0.75rem;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 6px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
}

.grid-button:hover {
  background: var(--surface-hover);
  color: var(--text-primary);
  border-color: var(--accent);
}

.grid-button.active {
  background: var(--accent);
  color: white;
  border-color: var(--accent);
}

.grid-button.active:hover {
  background: var(--accent-hover);
  color: white;
}

.data-summary {
  display: flex;
  gap: 2rem;
  flex-grow: 1;
}

.data-summary p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  margin: 0;
}

.data-summary span {
  color: var(--text-primary);
  font-weight: 500;
}

.org-chart-container {
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 8px;
  margin-bottom: 2rem;
  overflow: hidden;
}

.org-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding: 0.1rem 0.1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-bottom: 1px solid var(--border);
}

.org-chart-header:hover {
  background-color: var(--surface-hover);
}

.org-chart-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.org-chart-header h3 {
  margin: 0;
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.collapse-icon {
  color: var(--text-secondary);
  font-size: 0.75rem;
  transition: transform 0.2s ease;
}

.toggle-button {
  padding: 0.5rem 1rem;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 6px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
}

.toggle-button:hover {
  background: var(--surface-hover);
  color: var(--text-primary);
  border-color: var(--accent);
}

.zoom-controls {
  position: relative;
  height: 100%;
  min-height: 600px;
  padding: 1.5rem;
}

.zoom-buttons {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  z-index: 10;
}

.zoom-buttons button {
  padding: 0.5rem 1rem;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 6px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.zoom-buttons button:hover {
  background: var(--surface-hover);
  color: var(--text-primary);
  border-color: var(--accent);
}

.chart-wrapper {
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}

.org-node {
  padding: 1rem;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 8px;
  min-width: 100px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.org-node:hover {
  background: var(--surface-hover);
  border-color: var(--accent);
}

.org-node-header {
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.org-node-body {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.node-title {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.node-contract {
  color: var(--text-tertiary);
  font-size: 0.75rem;
}

.node-badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  background: var(--accent);
  color: white;
  border-radius: 4px;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

.leaver {
  opacity: 0.6;
  border-style: dashed;
}

.future-leaver {
  border-color: var(--warning);
}

.new-hire {
  border-color: var(--success);
}

.backfill {
  border-color: var(--info);
}

.employee-data-container {
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 8px;
  overflow: hidden;
}

.table-title {
  padding: 1rem;
  color: var(--text-primary);
  font-weight: 500;
  border-bottom: 1px solid var(--border);
}

.table-wrapper {
  overflow-x: auto;
  padding: 1rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
}

th {
  text-align: left;
  padding: 0.75rem;
  color: var(--text-secondary);
  font-weight: 500;
  border-bottom: 1px solid var(--border);
  white-space: nowrap;
  transition: background-color 0.2s;
  user-select: none;
}

th:hover {
  background-color: var(--surface-hover);
}

th span {
  margin-left: 0.5rem;
  color: var(--accent);
}

td {
  padding: 0.75rem;
  border-bottom: 1px solid var(--border);
  color: var(--text-primary);
}

tr:last-child td {
  border-bottom: none;
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickable-row:hover {
  background-color: var(--surface-hover);
}

.error-message {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: var(--error);
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.get-started-btn {
  background-color: #34D399;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.get-started-btn:hover {
  background-color: #10B981;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.get-started-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.search-container {
  flex: 1;
  max-width: 400px;
  margin-left: 1rem;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 10px;
  color: var(--text-secondary);
  font-style: normal;
}

.search-input {
  width: 100%;
  padding: 0.75rem 2.5rem;
  border: 1px solid var(--border);
  border-radius: 8px;
  font-size: 14px;
  background-color: var(--surface);
  color: var(--text-primary);
  transition: all 0.2s ease;
}

.search-input:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 3px var(--accent-transparent);
  background-color: var(--surface-hover);
}

.search-input::placeholder {
  color: var(--text-tertiary);
}

.clear-search {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.clear-search:hover {
  background-color: var(--surface-hover);
  color: var(--text-primary);
}

.search-results {
  margin-top: 0.5rem;
  font-size: 12px;
  color: var(--text-secondary);
  text-align: right;
}

.no-results {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
  font-style: italic;
  background-color: var(--surface);
} 