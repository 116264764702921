.scenario-role-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.scenario-role-modal-container {
  background: var(--background, #17181c);
  border-radius: 12px;
  padding: 32px;
  width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.4);
}

.scenario-role-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border, #27272a);
}

.scenario-role-modal-header h2 {
  margin: 0;
  color: var(--text-primary, #fff);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.025em;
}

.scenario-role-modal-close {
  background: none;
  border: none;
  color: var(--text-secondary, #71717a);
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
  margin: -8px;
  transition: all 0.2s ease;
  border-radius: 6px;
}

.scenario-role-modal-close:hover {
  color: var(--text-primary, #fff);
  background: var(--surface-hover, #27272a);
}

.scenario-role-modal-form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.scenario-role-modal-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.scenario-role-modal-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.scenario-role-modal .form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.scenario-role-modal .form-group label {
  color: var(--text-secondary, #a1a1aa);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.01em;
}

.scenario-role-modal .form-group input,
.scenario-role-modal .form-group select,
.scenario-role-modal .form-group textarea {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  background: var(--surface, #1d1e23);
  border: 1px solid var(--border, #27272a);
  border-radius: 6px;
  color: var(--text-primary, #fff);
  font-size: 14px;
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.scenario-role-modal .form-group textarea {
  height: 80px;
  padding: 12px;
  resize: vertical;
  font-family: inherit;
}

.scenario-role-modal .form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2.5 4.5L6 8L9.5 4.5' stroke='%23a1a1aa' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 32px;
}

.scenario-role-modal .form-group input:hover,
.scenario-role-modal .form-group select:hover,
.scenario-role-modal .form-group textarea:hover {
  border-color: var(--accent, #3b82f6);
  background: var(--surface-hover, #1f2937);
}

.scenario-role-modal .form-group input:focus,
.scenario-role-modal .form-group select:focus,
.scenario-role-modal .form-group textarea:focus {
  outline: none;
  border-color: var(--accent, #3b82f6);
  background: var(--surface-hover, #1f2937);
  box-shadow: 0 0 0 1px var(--accent, #3b82f6), 0 0 0 4px rgba(59, 130, 246, 0.1);
}

.scenario-role-modal .combobox-wrapper {
  position: relative;
  width: 100%;
}

.scenario-role-modal .combobox-wrapper input {
  width: 100%;
}

.scenario-role-modal optgroup {
  background: var(--surface, #1d1e23);
  color: var(--text-secondary, #a1a1aa);
  font-weight: 600;
  padding: 8px 0;
}

.scenario-role-modal option {
  background: var(--surface, #1d1e23);
  color: var(--text-primary, #fff);
  padding: 8px 12px;
}

.scenario-role-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  margin-top: 8px;
  border-top: 1px solid var(--border, #27272a);
}

.scenario-role-modal-buttons {
  display: flex;
  gap: 12px;
}

.scenario-role-modal-delete {
  padding: 0 20px;
  background: transparent;
  border: 1px solid var(--error, #dc2626);
  border-radius: 6px;
  color: var(--error, #dc2626);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.scenario-role-modal-delete:hover {
  background: var(--error, #dc2626);
  color: white;
}

.scenario-role-modal-cancel {
  padding: 0 20px;
  background: transparent;
  border: 1px solid var(--border, #27272a);
  border-radius: 6px;
  color: var(--text-secondary, #a1a1aa);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.scenario-role-modal-save {
  padding: 0 24px;
  background: var(--accent, #3b82f6);
  border: none;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.scenario-role-modal-cancel:hover {
  background: var(--surface-hover, #27272a);
  color: var(--text-primary, #fff);
}

.scenario-role-modal-save:hover {
  background: var(--accent-hover, #2563eb);
}

@media (max-width: 940px) {
  .scenario-role-modal-container {
    width: 95%;
    padding: 24px;
  }
  
  .scenario-role-modal-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

@media (max-width: 640px) {
  .scenario-role-modal-grid {
    grid-template-columns: 1fr;
  }

  .scenario-role-modal-footer {
    flex-direction: column;
    gap: 16px;
  }

  .scenario-role-modal-buttons {
    width: 100%;
  }

  .scenario-role-modal-delete,
  .scenario-role-modal-cancel,
  .scenario-role-modal-save {
    width: 100%;
  }
} 