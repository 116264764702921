/* Theme Variables - keeping your existing theme */
:root {
    /* Base colors */
    --background: #17181d;
    --surface: #1d1e23;
    --surface-hover: #2b2d33;
    --border: #363940;
    
    /* Text colors */
    --text-primary: #fff;
    --text-secondary: #a1a1aa;
    
    /* Accent colors */
    --accent: #5e6ad2;
    --accent-hover: #6e7ae2;
    --accent-light: rgba(94, 106, 210, 0.1);
    
    /* Status colors */
    --success: #2dd4bf;
    --success-light: rgba(45, 212, 191, 0.1);
    --warning: #f59e0b;
    --warning-light: rgba(245, 158, 11, 0.1);
    --error: #f43f5e;
    --error-light: rgba(244, 63, 94, 0.1);
    --success-dark: #20a99a;
}

/* Approval Queue Container */
.approval-queue {
    padding: 2rem;
    color: var(--text-primary);
}

/* Header Section */
.approval-header {
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

.approval-header h2 {
    margin: 0 0 1.5rem 0;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

/* Filter Controls */
.filter-controls {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.filter-controls select {
    padding: 0.75rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 4px;
    color: var(--text-primary);
    min-width: 150px;
    cursor: pointer;
    font-size: 0.875rem;
}

.filter-controls select:hover {
    border-color: var(--accent);
}

.filter-controls select:focus {
    outline: none;
    border-color: var(--accent);
    box-shadow: 0 0 0 1px var(--accent);
}

/* Sort Controls */
.sort-controls {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.sort-controls button {
    padding: 0.75rem 1rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 4px;
    color: var(--text-primary);
    cursor: pointer;
    transition: all 0.2s;
    font-size: 0.875rem;
}

.sort-controls button:hover {
    border-color: var(--accent);
    background: var(--surface-hover);
}

.sort-controls button.active {
    background: var(--accent-light);
    color: var(--accent);
    border-color: var(--accent);
}

/* Approval Items */
.approval-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.approval-item {
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 1rem;
    transition: all 0.2s;
    position: relative;
}

.approval-item:hover {
    border-color: var(--accent);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.approval-item .approval-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 0.75rem;
    background: none;
    border: none;
}

.approval-item .approval-header h3 {
    margin: 0;
    font-size: 1.1rem;
    color: var(--text-primary);
}

/* Status Badge */
.status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 16px;
    font-size: 0.75rem;
    font-weight: 500;
}

.status-badge.pending {
    background: var(--warning-light);
    color: var(--warning);
}

.status-badge.approved {
    background: var(--success-light);
    color: var(--success);
}

.status-badge.rejected {
    background: var(--error-light);
    color: var(--error);
}

/* Approval Details - Base styles for both sections */
.approval-details {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.75rem;
    align-items: start;
    padding-right: 150px;
    min-height: 80px;
}

.detail-group {
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
}

.detail-group label {
    color: var(--text-secondary);
    font-size: 0.875rem;
}

.detail-group span {
    color: var(--text-primary);
}

.approval-actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    border: none;
    position: absolute;
    right: 16px;
    bottom: 16px;
}

.approve-button,
.reject-button,
.reset-button {
    padding: 0.5rem 1rem;
    min-width: 80px;
    height: fit-content;
    font-size: 0.813rem;
}

.approve-button {
    background: var(--success-light);
    color: var(--success);
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.approve-button:hover:not(:disabled) {
    background: var(--success);
    color: white;
}

.reject-button {
    background: var(--error-light);
    color: var(--error);
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.reject-button:hover:not(:disabled) {
    background: var(--error);
    color: white;
}

.approve-button:disabled,
.reject-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Empty State */
.no-approvals {
    text-align: center;
    padding: 3rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
    color: var(--text-secondary);
}

/* Last Updated Timestamp */
.detail-group.timestamp {
    flex-basis: auto;
    max-width: none;
    border-top: none;
    padding-top: 0;
    margin-top: 0;
}

.detail-group.timestamp label,
.detail-group.timestamp span {
    font-size: 0.75rem;
}

.detail-group.timestamp span {
    color: var(--text-secondary);
}

/* Responsive Design */
@media (max-width: 768px) {
    .approval-queue {
        padding: 1rem;
    }

    .filter-controls {
        flex-direction: column;
    }

    .sort-controls {
        flex-direction: column;
    }

    .approval-item {
        padding: 1rem;
    }

    .approval-item .approval-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }

    .title-and-badge {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .request-type-badge {
        display: none;
    }

    .title-and-timestamp {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
    }

    .approval-details {
        display: none;
    }

    .status-badges {
        margin-bottom: 1rem;
    }

    .approval-actions {
        position: static;
        display: flex;
        gap: 0.5rem;
        margin-top: 0;
    }

    .approve-button,
    .reject-button,
    .reset-button {
        flex: 1;
        min-width: 0;
        padding: 0.75rem;
    }

    .reset-button {
        width: 100%;
    }
    
    .sort-controls button {
        width: 100%;
    }
}
.status-badges {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  
  .recruitment-status {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .recruitment-status .status-label {
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .recruitment-status.not-started {
    background: rgba(160, 160, 160, 0.2);
    color: #e0e0e0;
  }
  
  .recruitment-status.job-description {
    background: rgba(255, 170, 0, 0.2);
    color: #ffd700;
  }
  
  .recruitment-status.sourcing {
    background: rgba(0, 149, 255, 0.2);
    color: #60a5fa;
  }
  
  .recruitment-status.interviewing {
    background: rgba(34, 197, 94, 0.2);
    color: #4ade80;
  }
  
  .recruitment-status.offer-stage {
    background: rgba(99, 102, 241, 0.2);
    color: #818cf8;
  }
  
  .recruitment-status.completed {
    background: rgba(34, 197, 94, 0.2);
    color: #4ade80;
  }
  
  .recruitment-status.cancelled {
    background: rgba(239, 68, 68, 0.2);
    color: #f87171;
  }

/* Print Styles */
@media print {
    .approval-queue {
        padding: 0;
    }

    .filter-controls,
    .sort-controls,
    .approval-actions {
        display: none;
    }

    .approval-item {
        break-inside: avoid;
        page-break-inside: avoid;
        border: 1px solid #ddd;
    }
}

.reset-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    background: var(--accent-light);
    color: var(--accent);
}

.reset-button:hover {
    background: var(--accent);
    color: white;
}

/* Add these new styles for the sections */
.approval-sections {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.new-hires-section,
.backfills-section {
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 1.5rem;
}

.new-hires-section h3,
.backfills-section h3 {
    margin: 0 0 1.5rem 0;
    color: var(--text-primary);
    font-size: 1.2rem;
}

/* Styles for the title and badge container */
.title-and-badge {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.title-and-badge h3 {
    margin: 0;
    font-size: 1rem;
}

/* Request type badges */
.request-type-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 16px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
}

.request-type-badge.new {
    background: var(--accent-light);
    color: var(--accent);
}

.request-type-badge.backfill {
    background: var(--warning-light);
    color: var(--warning);
}

.request-type-badge.temporary {
    background-color: var(--warning-light);
    color: var(--warning);
}

.approval-item.completed {
    border-left: 4px solid var(--success);
}

.request-type-badge.hired {
    background-color: var(--success);
    color: white;
}

.approval-item.completed .title-and-badge h3 {
    color: var(--success);
}

.title-and-timestamp {
    display: flex;
    align-items: baseline;
    gap: 12px;
}

.title-and-timestamp .timestamp {
    color: var(--text-secondary);
    font-size: 0.75rem;
    border: none;
    padding: 0;
    margin: 0;
}

.completed-hires-section {
    margin-top: 2rem;
}

.completed-hires-section .section-wrapper {
    background: var(--surface);
    border: 1px solid rgb(48, 48, 48);
    border-radius: 8px;
    padding: 1.5rem;
}

.completed-hires-section h3 {
    color: #fff;
    margin-bottom: 1rem;
}

.completed-hires-section .approval-item {
    opacity: 0.8;
}

.completed-hires-section .approval-actions {
    display: none;
}