/* Main Container */
.talent-acquisition {
  padding: 2rem;
  color: var(--text-primary);
}

/* Header Section */
.ta-header {
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.ta-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.ta-header-top h2 {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
}

.header-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.hide-completed-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background: var(--surface);
  border: 1px solid var(--border);
  color: var(--text);
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.hide-completed-button:hover {
  background: var(--surface-hover);
}

.manage-team-button {
  padding: 0.5rem 1rem;
  background: var(--accent);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
}

/* Pipeline Stats */
.pipeline-stats {
  background: var(--surface-alt);
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
}

.stage-stats-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  align-items: start;
}

.stage-stat-item {
  background: var(--surface);
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid var(--border);
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.stage-stat-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.stage-stat-item.active {
  border-color: var(--accent);
  background: var(--surface-hover);
}

.stage-stat-label {
  color: var(--text-secondary);
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stage-stat-count {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.stage-stat-bar {
  height: 3px;
  background: var(--surface-alt);
  border-radius: 2px;
  overflow: hidden;
}

.stage-stat-bar-fill {
  height: 100%;
  background: var(--accent);
  border-radius: 2px;
  transition: width 0.3s ease;
}

@media (max-width: 1200px) {
  .stage-stats-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {
  .stage-stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Roles List */
.roles-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.role-card {
  background: var(--surface);
  border: 1px solid rgb(48, 48, 48);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  border-left-width: 4px;
}

.role-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.role-card.completed {
  border-left-color: var(--success);
}

.role-card-clickable {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 1;
}

/* Status-based left borders */
.role-card.not-started {
  border-left-color: var(--text-secondary);
}

.role-card.job-description,
.role-card.sourcing {
  border-left-color: var(--warning);
}

.role-card.interviewing,
.role-card.offer-stage {
  border-left-color: var(--accent);
}

.role-card.open {
  border-left-color: #3b82f6;
}

.role-card.completed {
  border-left-color: var(--success);
}

.role-card.cancelled {
  border-left-color: var(--error);
}

/* Role Header */
.role-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.75rem;
}

.role-controls {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  position: relative;
  z-index: 2;
}

.role-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 2;
}

.role-title h3 {
  margin: 0;
}

/* Select Inputs */
.ta-select,
.status-select {
  height: 38px;
  padding: 0 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--border);
  background: var(--surface);
  color: var(--text-primary);
  cursor: pointer;
  position: relative;
  z-index: 2;
  font-size: 0.875rem;
  min-width: 160px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2.5 4.5L6 8L9.5 4.5' stroke='%23a1a1aa' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  padding-right: 2.5rem;
}

.ta-select:focus,
.status-select:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 1px var(--accent);
}

/* Status Select Colors */
.status-select.not-started {
  border-color: var(--text-secondary);
  color: var(--text-secondary);
}

.status-select.job-description,
.status-select.sourcing {
  border-color: var(--warning);
  color: var(--warning);
}

.status-select.interviewing,
.status-select.offer-stage {
  border-color: var(--accent);
  color: var(--accent);
}

.status-select.open {
  border-color: #3b82f6;
  color: #3b82f6;
}

.status-select.completed {
  border-color: var(--success);
  color: var(--success);
}

.status-select.cancelled {
  border-color: var(--error);
  color: var(--error);
}

/* Add dynamic border color based on status */
.status-select {
  border-color: currentColor !important;
}

/* Role Types */
.role-type {
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 500;
}

.role-type.new-hire {
  background: var(--accent-light);
  color: var(--accent);
}

.role-type.backfill {
  background: rgba(255, 170, 0, 0.1);
  color: rgb(255, 170, 0);
}

/* Ensure temporary positions use purple */
.role-type.temporary,
.role-type.temporary-cover {
  background: rgba(147, 51, 234, 0.1) !important;
  color: rgb(147, 51, 234) !important;
}

.role-type.hired {
  background-color: var(--success);
  color: white;
}

/* More specific rules for role cards */
.role-card .role-type.temporary,
.role-card .role-type.temporary-cover {
  background: rgba(147, 51, 234, 0.1) !important;
  color: rgb(147, 51, 234) !important;
}

/* Override any default role-type styles for temporary positions */
.role-card .role-type[class*="temporary"] {
  background: rgba(147, 51, 234, 0.1) !important;
  color: rgb(147, 51, 234) !important;
}

/* Role Details */
.role-details {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  position: relative;
  z-index: 2;
}

.detail-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.detail-group label {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.detail-group.timestamp {
  grid-column: 1 / -1;
  border-top: 1px solid var(--border);
  padding-top: 1rem;
  margin-top: 0.5rem;
}

/* Media Queries */
@media (max-width: 768px) {
  .talent-acquisition {
      padding: 1rem;
  }

  .role-header {
      flex-direction: column;
      gap: 1rem;
  }

  .role-controls {
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;
  }

  .ta-select,
  .status-select {
      width: 100%;
  }

  .stage-stats-grid {
      grid-template-columns: repeat(2, 1fr);
  }
}

.role-card .title-and-badges {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.role-card h3 {
  margin: 0;
  font-size: 1rem;
  color: #fff;
}

.role-card .status-badges {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.role-card .job-id {
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background: transparent;
  color: white;
  border: 1px solid white;
}

.role-card .recruitment-status {
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background: rgba(0, 184, 163, 0.1);
  color: rgb(0, 184, 163);
}

.role-card .approval-status {
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.role-card .approval-status.approved {
  background: rgba(0, 184, 163, 0.1);
  color: rgb(0, 184, 163);
}

.role-card .approval-status.pending {
  background: rgba(255, 170, 0, 0.1);
  color: rgb(255, 170, 0);
}

.role-card .approval-status.rejected {
  background: rgba(255, 86, 86, 0.1);
  color: rgb(255, 86, 86);
}

.role-card .role-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}

.role-card .detail-group {
  display: flex;
  flex-direction: column;
}

.role-card .detail-group label {
  font-size: 0.75rem;
  color: #888;
  margin-bottom: 0.25rem;
}

.role-card .detail-group span {
  font-size: 0.9rem;
  color: #fff;
}

.role-card .role-actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 0.75rem;
}

.role-card button {
  font-size: 0.9rem;
  padding: 0.4rem 0.75rem;
}

.role-card .priority-tag {
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  text-transform: uppercase;
}

.role-card .priority-tag.high {
  background: rgba(255, 86, 86, 0.1);
  color: rgb(255, 86, 86);
}

.role-card .priority-tag.medium {
  background: rgba(255, 170, 0, 0.1);
  color: rgb(255, 170, 0);
}

.role-card .priority-tag.low {
  background: rgba(0, 184, 163, 0.1);
  color: rgb(0, 184, 163);
}

.role-card .timestamp {
  font-size: 0.75rem;
  color: #666;
  margin-top: 0.25rem;
}

.role-card .role-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.role-card .role-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.role-card h3 {
  margin: 0;
  font-size: 1rem;
  color: #fff;
}

.role-card .role-type {
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background: rgba(0, 184, 163, 0.1);
  color: rgb(0, 184, 163);
}

.role-card .timestamp {
  font-size: 0.75rem;
  color: #666;
}

.role-card .role-controls {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

/* Override the default role-type color for backfill */
.role-card .role-type.backfill {
  background: rgba(255, 170, 0, 0.1);
  color: rgb(255, 170, 0);
}

.unallocated-ta-section {
    margin-top: 24px;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 16px;
}

.unallocated-ta-section .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.unallocated-ta-section h3 {
    margin: 0;
    color: var(--text-primary);
    font-size: 1rem;
}

.unallocated-ta-section .role-count {
    color: var(--text-secondary);
    font-size: 0.875rem;
    padding: 4px 8px;
    background: var(--surface-hover);
    border-radius: 4px;
}

.unallocated-roles-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.unallocated-role-card {
    background: var(--surface-hover);
    border: 1px solid var(--border);
    border-radius: 6px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.unallocated-role-card .role-info h4 {
    margin: 0 0 8px 0;
    color: var(--text-primary);
    font-size: 0.875rem;
}

.unallocated-role-card .role-details {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.unallocated-role-card .role-details span {
    font-size: 0.75rem;
    color: var(--text-secondary);
    background: var(--surface);
    padding: 2px 6px;
    border-radius: 4px;
}

.unallocated-role-card .role-status .status-badge {
    font-size: 0.75rem;
    padding: 2px 6px;
    border-radius: 4px;
    background: var(--accent-light);
    color: var(--accent);
}

.offer-details {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.offer-details .detail-group {
  background-color: var(--surface-alt);
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid var(--border);
}

.offer-details .detail-group label {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.offer-details .detail-group span {
  font-weight: 500;
  color: var(--text-primary);
}

.sort-select {
  height: 38px;
  padding: 0 1rem;
  border: 1px solid var(--border);
  border-radius: 4px;
  background: var(--surface);
  color: var(--text-primary);
  font-size: 0.9rem;
  cursor: pointer;
}

.sort-select:focus {
  border-color: var(--accent);
  outline: none;
  box-shadow: 0 0 0 2px var(--accent-light);
}

.role-group {
  margin-bottom: 2rem;
}

.group-header {
  font-size: 1.1rem;
  color: var(--text-secondary);
  margin: 0 0 1rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.group-count {
  font-size: 0.9rem;
  color: var(--text-tertiary);
  font-weight: normal;
}

.job-id-input {
  padding: 6px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 8px;
  width: 120px;
  background-color: white;
}

.job-id-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}

.job-id-input::placeholder {
  color: #94a3b8;
}

.chat-icon {
  background: none;
  border: none;
  color: var(--text-secondary);
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s;
}

.chat-icon:hover {
  background: var(--surface-alt);
  color: var(--text-primary);
}

.chat-icon svg {
  width: 20px;
  height: 20px;
}