.notification-bell-container {
  position: relative;
  margin-right: 1rem;
}

.notification-bell-button {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: var(--text-secondary);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease;
}

.notification-bell-button:hover {
  color: var(--text-primary);
  background: var(--surface-hover);
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--accent);
  color: white;
  font-size: 0.75rem;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  font-weight: 500;
  transform: translate(25%, -25%);
}

.notification-dropdown {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 8px;
  width: 360px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  overflow: hidden;
}

.notification-header {
  padding: 1rem;
  border-bottom: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-header h3 {
  margin: 0;
  font-size: 1rem;
  color: var(--text-primary);
}

.clear-all-button {
  background: none;
  border: none;
  color: var(--accent);
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.clear-all-button:hover {
  background: var(--accent-light);
}

.notification-list {
  max-height: 400px;
  overflow-y: auto;
}

.notification-item {
  padding: 1rem;
  border-bottom: 1px solid var(--border);
  transition: background-color 0.2s ease;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item:hover {
  background: var(--surface-hover);
}

/* Organization Invite Styles */
.notification-item.invite .notification-content {
  margin-bottom: 0.75rem;
}

.org-name {
  margin: 0 0 0.25rem 0;
  font-weight: 500;
  color: var(--text-primary);
}

.notification-actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.notification-actions button {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.decline-button {
  background: var(--surface);
  border: 1px solid var(--border);
  color: var(--text-secondary);
}

.decline-button:hover {
  background: var(--surface-hover);
  border-color: var(--error);
  color: var(--error);
}

.accept-button {
  background: var(--accent);
  border: none;
  color: white;
}

.accept-button:hover {
  background: var(--accent-hover);
  transform: translateY(-1px);
}

/* Comment Mention Styles */
.notification-item.mention {
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.notification-item.mention.unread {
  background: var(--surface-alt);
}

.mention-message {
  color: var(--text-primary);
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  line-height: 1.4;
}

.mention-role {
  color: var(--text-secondary);
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}

.mention-text {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mention-time {
  color: var(--text-tertiary);
  font-size: 0.75rem;
}

.unread-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--accent);
  flex-shrink: 0;
  margin-top: 0.5rem;
}

.no-notifications {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  background: none;
}

.no-notifications p {
  margin: 0;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.no-notifications:hover {
  background: none;
}

@media (max-width: 768px) {
  .notification-dropdown {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 12px 12px 0 0;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
  }

  .notification-list {
    flex: 1;
    max-height: none;
  }
} 