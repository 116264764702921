.mobile-menu-container {
  display: none;
}

.mobile-menu-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.mobile-menu-button:hover {
  background: var(--surface-hover);
}

.mobile-menu-button.active {
  background: var(--surface-hover);
}

.hamburger-line {
  width: 20px;
  height: 2px;
  background-color: var(--text-primary);
  transition: all 0.3s ease;
  transform-origin: center;
}

.mobile-menu-button.active .hamburger-line:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
}

.mobile-menu-button.active .hamburger-line:nth-child(2) {
  opacity: 0;
}

.mobile-menu-button.active .hamburger-line:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
}

.mobile-menu {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  background: var(--background);
  border-bottom: 1px solid var(--border);
  padding: 1rem;
  transform: translateY(-100%);
  transition: all 0.3s ease;
  z-index: 1000;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
}

.mobile-menu.open {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mobile-nav-button {
  padding: 0.75rem 1rem;
  border: none;
  background: var(--surface);
  color: var(--text-secondary);
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: none;
  transition: all 0.2s;
  border-radius: 6px;
  display: flex;
  align-items: center;
  min-height: 44px;
}

.mobile-nav-button:hover {
  background: var(--surface-hover);
  color: var(--text-primary);
}

.mobile-nav-button.active {
  background: var(--gradient-1);
  color: white;
}

.mobile-nav-button.admin {
  color: var(--text-secondary);
}

.mobile-nav-button.admin.active {
  background: var(--gradient-1);
  color: white;
}

@media (max-width: 1024px) {
  .mobile-menu-container {
    display: block;
  }
} 