.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.ta-role-modal {
  background: #17181c;
  border-radius: 12px;
  width: 100%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  color: #fff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
}

.modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid #27272a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: #17181c;
  z-index: 10;
}

.modal-header h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.close-button {
  background: none;
  border: none;
  color: #71717a;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
  margin: -8px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.close-button:hover {
  color: #fff;
  background: #27272a;
}

.role-details-content {
  padding: 20px 24px;
}

.form-section {
  margin-bottom: 24px;
}

.form-section:last-child {
  margin-bottom: 0;
}

.form-section h3 {
  margin: 0 0 16px 0;
  font-size: 12px;
  font-weight: 600;
  color: #71717a;
  letter-spacing: 0.05em;
}

.section-header {
  margin-bottom: 24px;
  padding: 16px 24px;
  background: #1d1e23;
  border-radius: 8px;
}

.section-header h3 {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  text-align: left;
  margin-bottom: 8px;
}

.section-description {
  color: #71717a;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.subsection {
  margin-bottom: 24px;
  padding: 16px;
  background: #1d1e23;
  border-radius: 8px;
}

.subsection:last-child {
  margin-bottom: 0;
}

.subsection h4 {
  color: #71717a;
  font-size: 12px;
  font-weight: 600;
  margin: 0 0 12px 0;
  letter-spacing: 0.05em;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
}

.role-details-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto auto;
  grid-template-areas: 
    "title function level team org"
    "jobid manager month year priority";
  gap: 12px;
}

.role-details-grid > .form-group:nth-child(1) { grid-area: title; }
.role-details-grid > .form-group:nth-child(2) { grid-area: function; }
.role-details-grid > .form-group:nth-child(3) { grid-area: level; }
.role-details-grid > .form-group:nth-child(4) { grid-area: team; }
.role-details-grid > .form-group:nth-child(5) { grid-area: org; }
.role-details-grid > .form-group:nth-child(6) { grid-area: jobid; }
.role-details-grid > .form-group:nth-child(7) { grid-area: manager; }
.role-details-grid > .form-group:nth-child(8) { grid-area: month; }
.role-details-grid > .form-group:nth-child(9) { grid-area: year; }
.role-details-grid > .form-group:nth-child(10) { grid-area: priority; }

.form-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.form-group label {
  font-size: 12px;
  color: #71717a;
  font-weight: 500;
}

.form-group input,
.form-group select {
  height: 32px;
  padding: 0 12px;
  background: #17181c;
  border: 1px solid #27272a;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  transition: all 0.2s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #3b82f6;
  outline: none;
}

.form-group input::placeholder {
  color: #52525b;
}

.location-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #1d1e23;
  border: 1px solid #27272a;
  border-radius: 8px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 20;
}

.suggestion-item {
  padding: 8px 12px;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease;
}

.suggestion-item:hover {
  background: #27272a;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px 24px;
  border-top: 1px solid #27272a;
  position: sticky;
  bottom: 0;
  background: #17181c;
  z-index: 10;
}

.save-button,
.cancel-button {
  height: 32px;
  padding: 0 16px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 80px;
}

.save-button {
  background: #3b82f6;
  border: none;
  color: white;
}

.save-button:hover {
  background: #2563eb;
}

.cancel-button {
  background: transparent;
  border: 1px solid #27272a;
  color: #71717a;
}

.cancel-button:hover {
  background: #27272a;
  color: #fff;
}

@media (max-width: 1000px) {
  .form-grid,
  .role-details-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .role-details-grid {
    grid-template-areas: none;
  }
}

@media (max-width: 768px) {
  .form-grid,
  .role-details-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .form-grid,
  .role-details-grid {
    grid-template-columns: 1fr;
  }
}

.form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%2371717a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 36px;
  padding-top: 0;
  padding-bottom: 0;
}

.combobox-container {
  position: relative;
  width: 100%;
}

.combobox-input {
  width: 100%;
  height: 32px;
  padding: 0 12px;
  background: #17181c;
  border: 1px solid #27272a;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.combobox-input:focus {
  border-color: #3b82f6;
  outline: none;
}

.combobox-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background: #1d1e23;
  border: 1px solid #27272a;
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 30;
}

.combobox-option {
  padding: 8px 12px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.combobox-option:hover {
  background: #27272a;
}

.combobox-input::placeholder {
  color: #52525b;
}