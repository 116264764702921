.org-chart-container {
    width: 100%;
    background: var(--surface);
    border-radius: 8px;
    margin-top: 1rem;
}
  
.section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 0 1.5rem;
    background: var(--surface);
    border-bottom: 1px solid var(--border);
    min-height: 3.5rem;
}
  
.section-header h3 {
    margin: 0;
    flex: 1;
    text-align: center;
}
  
.chart-controls {
    display: flex;
    gap: 0.5rem;
}
  
.toggle-visibility {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    background: var(--surface-hover);
    border: 1px solid var(--border);
    color: var(--text-primary);
    cursor: pointer;
    transition: all 0.2s ease;
}
  
.toggle-visibility:hover {
    background: var(--surface-hover);
    border-color: var(--accent);
}
  
.org-node {
    padding: 1rem;
    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 6px;
    min-width: 200px;
    cursor: pointer;
    transition: all 0.2s ease;
}
  
.org-node:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}
  
.org-node.leaver {
    border: 2px solid #dc3545;  /* Red border for past leavers */
}
  
.org-node.future-leaver {
    border: 2px solid #ffc107;  /* Yellow border for future leavers */
}
  
.org-node.new-hire {
    border: 2px solid #2dd4bf;  /* Teal border for new hires */
}
  
.org-node.backfill {
    border: 2px solid #6366f1;  /* Indigo border for backfills */
}
  
.org-node.planned-hire {
    border: 2px dashed #4a5568;
}
  
.node-badge {
    margin-top: 0.5rem;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 4px;
    text-align: center;
    background-color: var(--surface);
    color: var(--text-secondary);
}
  
.org-node-header {
    font-weight: 500;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
}
  
.org-node-body {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}
  
.node-title {
    font-size: 0.875rem;
    color: var(--text-secondary);
}
  
.node-contract {
    font-size: 0.875rem;
    color: var(--text-secondary);
}
  
.zoom-controls {
    border: 1px solid var(--border);
    border-radius: 6px;
    background: var(--surface);
    overflow: hidden;
    min-height: 600px;
    display: flex;
    flex-direction: column;
}
  
.chart-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    min-height: 500px;
    position: relative;
}
  
.transform-component-module_wrapper__7HFJe {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
  
.transform-component-module_content__FBWxo {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    transform-origin: center center !important;
}
  
.zoom-buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    padding: 1rem;
}
  
.zoom-buttons button {
    padding: 0.5rem 1rem;
    background-color: var(--surface);
    border: 1px solid var(--border);
    color: var(--text-secondary);
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}
  
.zoom-buttons button:hover {
    background-color: var(--surface-hover);
    color: var(--text-primary);
}
  
.org-chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
  
.toggle-button {
    padding: 0.5rem 1rem;
    background-color: var(--surface);
    border: 1px solid var(--border);
    color: var(--text-secondary);
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}
  
.toggle-button:hover {
    background-color: var(--surface-hover);
    color: var(--text-primary);
}
  
.scenario-badge {
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 4px;
    margin-top: 4px;
}
  
.scenario-badge.base-case {
    background-color: rgba(59, 130, 246, 0.1);
    color: #3b82f6;
}
  
.scenario-badge.bull-case {
    background-color: rgba(16, 185, 129, 0.1);
    color: #10b981;
}
  
.scenario-badge.bear-case {
    background-color: rgba(239, 68, 68, 0.1);
    color: #ef4444;
}
  
.scenario-badge.exceptions {
    background-color: rgba(245, 158, 11, 0.1);
    color: #f59e0b;
}
  
.node-team {
    font-size: 0.875rem;
    color: var(--text-secondary);
}
  
.node-start-date {
    font-size: 11px;
    color: #6b7280;
    margin-top: 4px;
}
  
.org-chart {
    padding: 20px;
    overflow: auto;
    max-height: 800px;
}
  
.no-data {
    text-align: center;
    color: #6b7280;
    padding: 20px;
}
  
.node-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
}
  
.node-separator {
    color: var(--text-secondary);
    opacity: 0.5;
}
  
.node-title {
    font-size: 0.875rem;
    color: var(--text-primary);
}