:root {
  --background: #17181d;
  --background-rgb: 23, 24, 29;
  --surface: #1d1e23;
  --surface-hover: #2b2d33;
  --border: #363940;
  --text-primary: #fff;
  --text-secondary: #a1a1aa;
  --accent: #5e6ad2;
  --accent-hover: #6e7ae2;
  --success: #2dd4bf;
  --error: #f43f5e;
  --gradient-1: #4f46e5;
  --gradient-2: #06b6d4;
  --gradient-3: #3b82f6;
}

body {
  background-color: var(--background);
  color: var(--text-primary);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App {
  min-height: 100vh;
  background-color: var(--background);
}

.view-content {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  overflow-x: hidden;
}

.app-header {
  background-color: rgba(var(--background-rgb), 0.7);
  border-bottom: 1px solid var(--border);
  position: sticky;
  top: 0;
  z-index: 100;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  height: 64px;
  display: flex;
  align-items: center;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  max-width: 1400px;
  margin: 0 auto;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-brand {
  display: flex;
  align-items: center;
}

.logo-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.2s ease;
  display: flex;
  align-items: center;
}

.logo-button:hover {
  opacity: 0.8;
}

.logo-image {
  height: 24px;
  width: auto;
}

.logo-button h1 {
  margin: 0;
  color: var(--text-primary);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.logo-button .gradient-text {
  background: linear-gradient(135deg, #6366f1 0%, #2dd4bf 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.main-nav {
  display: flex;
  gap: 2px;
  align-items: center;
  height: 32px;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 6px;
  padding: 2px;
}

.nav-button {
  height: 100%;
  padding: 0 12px;
  border: none;
  background: none;
  color: var(--text-secondary);
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: none;
  transition: all 0.2s;
  white-space: nowrap;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.nav-button:hover {
  background-color: var(--surface-hover);
  color: var(--text-primary);
}

.nav-button.active {
  background-color: var(--gradient-1);
  color: white;
}

.nav-button.admin {
  margin-left: 8px;
  color: var(--text-secondary);
}

.nav-button.admin.active {
  background-color: var(--gradient-1);
  color: white;
}

.header-right {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.auth-button {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: 1px solid var(--border);
  background: var(--surface);
  color: var(--text-primary);
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.auth-button:hover {
  background: var(--surface-hover);
  border-color: var(--border-hover);
}

.main-content {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.add-employee-btn {
  padding: 0.5rem 1rem;
  background-color: var(--accent);
  color: var(--text-primary);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.add-employee-btn:hover {
  background-color: var(--accent-hover);
}

.export-btn {
  padding: 0.5rem 1rem;
  background-color: var(--surface);
  color: var(--text-primary);
  border: 1px solid var(--border);
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.export-btn:hover {
  background-color: var(--surface-hover);
}

.export-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.upload-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.upload-btn {
  padding: 0.5rem 1rem;
  background-color: var(--accent);
  color: var(--text-primary);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.upload-btn:hover {
  background-color: var(--accent-hover);
}

.error-message {
  color: var(--error);
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.data-summary {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.data-summary p {
  background-color: var(--surface);
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid var(--border);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 150px;
  margin: 0;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.data-summary span {
  color: var(--text-primary);
  font-size: 1.5rem;
  font-weight: 500;
}

.org-chart-container {
  width: 100%;
  overflow-x: auto;
  padding: 1rem;
  background: var(--surface);
  border-radius: 8px;
  margin: -1rem;
  padding: 1rem;
}

.org-node {
  padding: 1rem;
  background-color: var(--background);
  border: 1px solid var(--border);
  border-radius: 6px;
  min-width: 200px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.org-node.leaver {
  border: 2px solid #dc3545;  /* Red border for past leavers */
}

.org-node.future-leaver {
  border: 2px solid #ffc107;  /* Yellow border for future leavers */
}

.org-node.new-hire {
  border: 2px solid #2dd4bf;  /* Teal border for new hires */
}

.org-node.backfill {
  border: 2px solid #6366f1;  /* Indigo border for backfills */
}

.node-badge {
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 4px;
  text-align: center;
  background-color: var(--surface);
  color: var(--text-secondary);
}

.org-node-header {
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.org-node-body {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.node-title {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.node-contract {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.org-node:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.zoom-controls {
  position: relative;
  background: var(--surface);
  border-radius: 8px;
  padding: 1rem;
  height: 600px;
  overflow: hidden;
}

.chart-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem;
}

.zoom-buttons {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
  display: flex;
  gap: 0.5rem;
  background: var(--surface);
  padding: 0.5rem;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}

.zoom-buttons button {
  padding: 0.5rem 1rem;
  background-color: var(--surface);
  border: 1px solid var(--border);
  color: var(--text-secondary);
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.zoom-buttons button:hover {
  background-color: var(--surface-hover);
  color: var(--text-primary);
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.table-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

th, td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--border);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  background: var(--surface-hover);
  color: var(--text-primary);
  font-weight: 500;
}

td {
  color: var(--text-secondary);
}

tr:hover td {
  background-color: var(--surface-hover);
}

.table-cell-clickable {
  cursor: pointer;
}

.table-cell-clickable:hover {
  color: var(--accent);
}

.future-leaver {
  border: 1px solid #ffc107;
}

.leaver {
  border: 1px solid #dc3545;
}

.org-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.toggle-button {
  padding: 0.5rem 1rem;
  background-color: var(--surface);
  border: 1px solid var(--border);
  color: var(--text-secondary);
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.toggle-button:hover {
  background-color: var(--surface-hover);
  color: var(--text-primary);
}

.upload-instructions {
  background-color: var(--surface);
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid var(--border);
}

.upload-instructions h3 {
  color: var(--text-primary);
  margin-top: 0;
}

.upload-instructions ul {
  color: var(--text-secondary);
  padding-left: 1.5rem;
}

.upload-instructions li {
  margin: 0.5rem 0;
}

@media (max-width: 768px) {
  .view-content {
    padding: 1rem;
  }

  .header-actions {
    flex-direction: column;
    align-items: stretch;
  }

  .data-summary {
    grid-template-columns: 1fr;
  }

  .app-header {
    height: auto;
    padding: 12px 0;
  }

  .header-content {
    flex-direction: column;
    gap: 12px;
    padding: 0 16px;
  }

  .header-left {
    width: 100%;
  }

  .main-nav {
    width: 100%;
    overflow-x: auto;
    padding: 4px;
    height: 40px;
    margin: 0;
  }

  .nav-button {
    padding: 0 16px;
    font-size: 14px;
  }

  .header-right {
    width: 100%;
    justify-content: flex-end;
  }
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickable-row:hover {
  background-color: var(--surface-hover);
}

.welcome-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--surface);
  padding: 3rem;
  border-radius: 16px;
  border: 1px solid var(--border);
  width: 90%;
  max-width: 500px;
  z-index: 1001;
  text-align: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  animation: modalFadeIn 0.3s ease-out;
}

.welcome-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(4px);
  z-index: 1000;
  animation: backdropFadeIn 0.3s ease-out;
}

.welcome-modal h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.welcome-modal h2 .gradient-text {
  background: linear-gradient(135deg, #6366f1 0%, #2dd4bf 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}

.welcome-modal p {
  color: var(--text-secondary);
  font-size: 1.1rem;
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

.welcome-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.welcome-button {
  padding: 0.875rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.welcome-button.primary {
  background: linear-gradient(135deg, var(--accent) 0%, #6366f1 100%);
  color: white;
  border: none;
}

.welcome-button.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.3);
}

.welcome-button.secondary {
  background: transparent;
  border: 1px solid var(--border);
  color: var(--text-primary);
}

.welcome-button.secondary:hover {
  background: var(--surface-hover);
  transform: translateY(-2px);
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -48%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes backdropFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.exchange-rate-btn {
  padding: 0.5rem 1rem;
  background-color: var(--surface);
  color: var(--text-primary);
  border: 1px solid var(--border);
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.exchange-rate-btn:hover {
  background-color: var(--surface-hover);
}

.modal-panel {
  transform: translate(0);
  background-color: var(--surface);
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid var(--border);
  max-width: 500px;
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 50;
}

.modal-title {
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.rates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.rate-input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.rate-input-group label {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.rate-input-group input {
  padding: 0.5rem;
  background-color: var(--background);
  border: 1px solid var(--border);
  border-radius: 4px;
  color: var(--text-primary);
  font-size: 0.875rem;
}

.rate-input-group input.error {
  border-color: var(--error);
}

.rate-input-group .error-message {
  color: var(--error);
  font-size: 0.75rem;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.cancel-button {
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 1px solid var(--border);
  color: var(--text-primary);
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.cancel-button:hover {
  background-color: var(--surface-hover);
}

.submit-button {
  padding: 0.5rem 1rem;
  background-color: var(--accent);
  color: var(--text-primary);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.submit-button:hover {
  background-color: var(--accent-hover);
}

/* Headless UI Dialog Transitions */
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.scale-95 {
  transform: scale(0.95);
}

.scale-100 {
  transform: scale(1);
}

/* Ensure consistent spacing between org chart and table */
.org-chart-container + .table-container {
  margin-top: 3rem;
}

.employee-data-container {
  width: 100%;
  overflow-x: auto;
  padding: 1rem;
  background: var(--surface);
  border-radius: 8px;
  margin-top: 3rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

.auth-button {
  padding: 0.5rem 1rem;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 6px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
}

.auth-button:hover {
  background: var(--surface-hover);
  color: var(--text-primary);
  border-color: var(--accent);
}

.header-right {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

@media (max-width: 768px) {
  .header-right {
    width: 100%;
    justify-content: flex-end;
  }
}

.app {
  min-height: 100vh;
  background-color: var(--background);
}

.nav-bar {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--surface);
  border-bottom: 1px solid var(--border);
}

.nav-link {
  text-decoration: none;
  color: var(--text-primary);
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s;
}

.nav-link:hover {
  background-color: var(--surface-hover);
}

.nav-link.active {
  background-color: var(--primary);
  color: white;
}

@media (max-width: 1024px) {
  .header-content {
    padding: 0.75rem 1rem;
  }

  .main-nav {
    display: none;
  }

  .header-right {
    margin-left: auto;
  }
}

@media (max-width: 640px) {
  .header-content {
    padding: 0.75rem 0.5rem;
  }

  .logo-button h1 {
    font-size: 1.25rem;
  }
}

.main-nav.desktop-only {
  display: flex;
  gap: 2px;
  align-items: center;
  height: 32px;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 6px;
  padding: 2px;
}

@media (max-width: 1024px) {
  .main-nav.desktop-only {
    display: none;
  }

  .app-header {
    height: auto;
    min-height: 64px;
  }

  .header-content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    padding: 0.75rem 1rem;
    gap: 1rem;
  }

  .header-left {
    justify-self: start;
  }

  .header-brand {
    justify-self: center;
  }

  .header-right {
    justify-self: end;
  }

  /* Hide Analysis tab */
  .nav-button[href="/analysis"],
  .mobile-nav-button[href="/analysis"],
  .nav-button[href="/compensation"],
  .mobile-nav-button[href="/compensation"] {
    display: none !important;
  }

  /* Hide Scenario Planning extras */
  .total-headcount-plan,
  .scenario-header-actions,
  .scenario-buttons {
    display: none !important;
  }

  /* Hide Talent Acquisition stats */
  .pipeline-stats,
  .stats-row {
    display: none !important;
  }

  /* Hide Team Viewer extras */
  .data-summary,
  .toggle-button[aria-label*="new hire"],
  .toggle-button[aria-label*="planned hire"] {
    display: none !important;
  }

  .view-content {
    padding: 1rem;
  }

  .header-actions {
    flex-direction: column;
    align-items: stretch;
  }

  .data-summary {
    grid-template-columns: 1fr;
  }

  /* Hide all org chart related components on mobile */
  .org-chart-container,
  .org-chart-header,
  .org-chart-header-wrapper,
  .org-chart-content,
  .org-chart-section,
  .org-chart-section-content,
  .zoom-controls,
  .chart-wrapper,
  .toggle-button[aria-label*="org chart"],
  .collapsible-section {
    display: none !important;
  }

  /* Adjust table styles for mobile */
  .table-container {
    overflow-x: auto;
    margin: 0 -1rem;
    padding: 0 1rem;
  }

  /* Hide all columns except first name and surname on mobile */
  .table-cell:not(:nth-child(1)):not(:nth-child(2)) {
    display: none;
  }

  .table-header:not(:nth-child(1)):not(:nth-child(2)) {
    display: none;
  }

  /* Adjust modal styles for mobile */
  .modal-content {
    width: 95% !important;
    max-width: none !important;
    margin: 1rem !important;
    padding: 1rem !important;
  }

  .modal-body {
    padding: 1rem !important;
  }

  .modal-footer {
    padding: 1rem !important;
  }
}

@media (max-width: 640px) {
  .header-content {
    padding: 0.75rem 0.5rem;
  }

  .logo-button h1 {
    font-size: 1.25rem;
  }

  .auth-button {
    padding: 0.5rem;
    font-size: 0.75rem;
  }
}

/* Mobile Form and Modal Optimizations */
@media (max-width: 1024px) {
  /* Form Controls */
  input,
  select,
  textarea,
  .form-control {
    font-size: 16px !important; /* Prevents iOS zoom on focus */
    min-height: 44px !important; /* Minimum touch target size */
    padding: 10px 12px !important;
  }

  /* Buttons */
  button,
  .btn,
  [role="button"],
  .nav-button,
  .mobile-nav-button {
    min-height: 44px !important;
    padding: 10px 16px !important;
    touch-action: manipulation;
  }

  /* Modal Improvements */
  .modal-content {
    width: 95% !important;
    max-width: none !important;
    margin: 1rem auto !important;
    padding: 1.5rem !important;
    border-radius: 12px !important;
    max-height: 90vh !important;
    overflow-y: auto !important;
  }

  .modal-header {
    padding: 1rem !important;
    position: sticky !important;
    top: 0 !important;
    background: var(--background) !important;
    z-index: 1 !important;
  }

  .modal-body {
    padding: 1rem !important;
    -webkit-overflow-scrolling: touch !important;
  }

  .modal-footer {
    padding: 1rem !important;
    position: sticky !important;
    bottom: 0 !important;
    background: var(--background) !important;
    z-index: 1 !important;
  }

  /* Form Layout */
  .form-row,
  .form-group {
    display: flex !important;
    flex-direction: column !important;
    gap: 1rem !important;
  }

  /* Form Labels */
  label {
    font-size: 14px !important;
    margin-bottom: 4px !important;
  }

  /* Spacing between form elements */
  .form-group + .form-group {
    margin-top: 1.5rem !important;
  }

  /* Touch-friendly checkboxes and radio buttons */
  input[type="checkbox"],
  input[type="radio"] {
    width: 24px !important;
    height: 24px !important;
    margin-right: 8px !important;
  }

  /* Form validation messages */
  .error-message,
  .help-text {
    font-size: 14px !important;
    padding: 4px 0 !important;
  }
}

/* Current Organization Mobile Adjustments */
@media (max-width: 1024px) {
  /* ... existing mobile styles ... */

  /* Hide all buttons except Add Employee */
  .exchange-rate-btn,
  .export-btn,
  .upload-section {
    display: none !important;
  }

  /* Hide summary stats */
  .data-summary {
    display: none !important;
  }

  /* Improve modal layout */
  .modal-content {
    width: 95% !important;
    max-width: none !important;
    margin: 1rem auto !important;
    padding: 1rem !important;
    border-radius: 12px !important;
    max-height: 90vh !important;
    overflow-y: auto !important;
  }

  .modal-body {
    padding: 1rem !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 1rem !important;
  }

  .modal-body .form-group {
    margin-bottom: 1rem !important;
  }

  .modal-footer {
    padding: 1rem !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 0.5rem !important;
  }

  .modal-footer button {
    width: 100% !important;
  }

  /* Form controls in modals */
  .modal-body input,
  .modal-body select {
    width: 100% !important;
    min-height: 44px !important;
    padding: 0.5rem !important;
  }

  /* Labels in modals */
  .modal-body label {
    display: block !important;
    margin-bottom: 0.25rem !important;
  }
}