:root {
  --surface: rgba(39, 39, 42, 0.4);
  --surface-hover: rgba(39, 39, 42, 0.6);
  --border: rgba(63, 63, 70, 0.4);
  --text-primary: #fff;
  --accent: #6366F1;
  --error: #EF4444;
  --error-light: rgba(239, 68, 68, 0.1);
  --hover: rgba(63, 63, 70, 0.6);
  --text: #fff;
}

.admin-panel {
  padding: 2rem;
}

.admin-panel h2 {
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  letter-spacing: -0.02em;
}

.admin-panel section {
  background: rgba(39, 39, 42, 0.4);
  border: 1px solid rgba(63, 63, 70, 0.4);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  backdrop-filter: blur(8px);
  transition: all 0.2s ease;
}

.admin-panel section:hover {
  background: rgba(39, 39, 42, 0.6);
  border-color: rgba(63, 63, 70, 0.6);
}

.admin-panel h3 {
  color: #A1A1AA;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.admin-panel h4 {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

/* Users List */
.users-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 500px;
  overflow-y: auto;
}

.users-list::-webkit-scrollbar {
  width: 8px;
}

.users-list::-webkit-scrollbar-track {
  background: rgba(39, 39, 42, 0.2);
  border-radius: 4px;
}

.users-list::-webkit-scrollbar-thumb {
  background: rgba(63, 63, 70, 0.6);
  border-radius: 4px;
}

.users-list::-webkit-scrollbar-thumb:hover {
  background: rgba(63, 63, 70, 0.8);
}

.user-item {
  padding: 0.75rem;
  border-bottom: 1px solid rgba(63, 63, 70, 0.4);
  transition: background-color 0.2s ease;
}

.user-item:last-child {
  border-bottom: none;
}

.user-item:hover {
  background: rgba(63, 63, 70, 0.3);
}

.user-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgba(63, 63, 70, 0.4);
  flex-shrink: 0;
}

.user-details {
  flex: 1;
  min-width: 0;
}

.user-primary {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.125rem;
  flex-wrap: wrap;
}

.user-name {
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-badge {
  background: rgba(99, 102, 241, 0.2);
  color: #818CF8;
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  flex-shrink: 0;
}

.user-email {
  color: #A1A1AA;
  font-size: 0.75rem;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-joined {
  color: #71717A;
  font-size: 0.75rem;
  display: none;
}

/* User Actions */
.user-actions {
  display: flex;
  gap: 0.5rem;
  flex-shrink: 0;
}

/* Role Management */
.role-button {
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  white-space: nowrap;
}

.role-button.make-admin {
  background: rgba(99, 102, 241, 0.2);
  color: #818CF8;
  border: 1px solid rgba(99, 102, 241, 0.4);
}

.role-button.make-admin:hover {
  background: rgba(99, 102, 241, 0.3);
  transform: translateY(-1px);
}

.role-button.remove-admin {
  background: rgba(239, 68, 68, 0.2);
  color: #EF4444;
  border: 1px solid rgba(239, 68, 68, 0.4);
}

.role-button.remove-admin:hover {
  background: rgba(239, 68, 68, 0.3);
  transform: translateY(-1px);
}

/* Remove User */
.remove-button {
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  background: rgba(239, 68, 68, 0.2);
  color: #EF4444;
  border: 1px solid rgba(239, 68, 68, 0.4);
  white-space: nowrap;
}

.remove-button:hover {
  background: rgba(239, 68, 68, 0.3);
  transform: translateY(-1px);
}

.confirm-remove {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(239, 68, 68, 0.1);
  padding: 0.375rem;
  border-radius: 0.375rem;
  border: 1px solid rgba(239, 68, 68, 0.2);
}

.confirm-remove span {
  color: #EF4444;
  font-size: 0.75rem;
  font-weight: 500;
  white-space: nowrap;
}

.confirm-button {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  white-space: nowrap;
}

.confirm-button.confirm-yes {
  background: #EF4444;
  color: white;
}

.confirm-button.confirm-yes:hover {
  background: #DC2626;
}

.confirm-button.confirm-no {
  background: rgba(63, 63, 70, 0.4);
  color: #fff;
}

.confirm-button.confirm-no:hover {
  background: rgba(63, 63, 70, 0.6);
}

/* Invite Form */
.invite-form {
  margin-bottom: 1rem;
}

.invite-form .form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  position: relative;
}

.invite-form .input-group {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  width: 100%;
}

.invite-form .role-header {
  display: flex;
  align-items: center;
  position: absolute;
  transform: translateY(-24px);
  margin-left: calc(100% - 320px);
}

.invite-form .form-label {
  color: #A1A1AA;
  font-size: 0.875rem;
  font-weight: 500;
}

.invite-form .tooltip-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.invite-form .info-icon {
  color: #A1A1AA;
  margin-left: 0.5rem;
  cursor: help;
}

.invite-form .tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  background: #27272A;
  border: 1px solid #3F3F46;
  border-radius: 8px;
  width: max-content;
  max-width: 300px;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.4;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.invite-form .tooltip::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: #3F3F46 transparent transparent transparent;
}

.invite-form .tooltip-wrapper:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.invite-form .email-input {
  flex: 1;
  min-width: 0;
  background: rgba(39, 39, 42, 0.4);
  border: 1px solid rgba(63, 63, 70, 0.4);
  color: #fff;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  height: 48px;
}

.invite-form .email-input:focus {
  outline: none;
  border-color: #6366F1;
  background: rgba(39, 39, 42, 0.6);
}

.invite-form .email-input::placeholder {
  color: #71717A;
}

.invite-form .role-select {
  width: 200px;
  padding: 0.75rem;
  background: rgba(39, 39, 42, 0.4);
  border: 1px solid rgba(63, 63, 70, 0.4);
  border-radius: 8px;
  color: #fff;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 48px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%2371717a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 28px;
  flex-shrink: 0;
}

.invite-form .role-select:hover {
  background: rgba(39, 39, 42, 0.6);
  border-color: #6366F1;
}

.invite-form .role-select:focus {
  outline: none;
  border-color: #6366F1;
  box-shadow: 0 0 0 1px #6366F1;
}

.invite-form .role-select option {
  background: #27272A;
  color: #fff;
  padding: 12px;
  font-size: 0.875rem;
  min-height: 48px;
}

.invite-button {
  flex-shrink: 0;
  background: #6366F1;
  color: white;
  border: none;
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 48px;
  white-space: nowrap;
}

.invite-button:hover {
  background: #4F46E5;
}

.invite-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Status Messages */
.status-message {
  margin-top: 0.75rem;
  padding: 0.75rem;
  border-radius: 6px;
  font-size: 0.875rem;
}

.status-message.error {
  background: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.2);
  color: #EF4444;
}

.status-message.success {
  background: rgba(34, 197, 94, 0.1);
  border: 1px solid rgba(34, 197, 94, 0.2);
  color: #22C55E;
}

/* Pending Invites */
.invites-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.invite-item {
  padding: 0.75rem;
  border-bottom: 1px solid rgba(63, 63, 70, 0.4);
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invite-item:last-child {
  border-bottom: none;
}

.invite-item:hover {
  background: rgba(63, 63, 70, 0.3);
}

.invite-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 1;
  min-width: 0;
}

.invite-email {
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invite-meta {
  display: flex;
  gap: 1rem;
  color: #A1A1AA;
  font-size: 0.75rem;
}

.invite-date, .invite-by {
  color: #A1A1AA;
  font-size: 0.75rem;
}

/* Invite Actions */
.invite-actions {
  display: flex;
  gap: 8px;
}

.accept-button,
.reject-button,
.revoke-button {
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  color: white;
}

.accept-button {
  background-color: #6366F1;
}

.accept-button:hover {
  background-color: #4F46E5;
  transform: translateY(-1px);
}

.reject-button {
  background-color: #4B5563;
}

.reject-button:hover {
  background-color: #374151;
  transform: translateY(-1px);
}

.revoke-button {
  background-color: #DC2626;
}

.revoke-button:hover {
  background-color: #B91C1C;
  transform: translateY(-1px);
}

.invite-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.invite-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.invite-email {
  font-weight: 500;
}

.invite-date {
  font-size: 0.9em;
  color: #666;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none !important;
}

/* Organization Management Section */
.current-org-section {
  background: rgba(39, 39, 42, 0.4);
  border: 1px solid rgba(63, 63, 70, 0.4);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  backdrop-filter: blur(8px);
  transition: all 0.2s ease;
}

.current-org-section h3 {
  color: #A1A1AA;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 1.25rem;
}

.current-org {
  background: rgba(39, 39, 42, 0.3);
  border: 1px solid rgba(63, 63, 70, 0.3);
  border-radius: 8px;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.current-org h4 {
  color: #A1A1AA;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
}

.current-org p {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 1rem;
}

.create-org-section {
  margin-top: 1rem;
}

.create-org-button {
  background: #6366F1;
  color: white;
  border: none;
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.create-org-button:hover {
  background: #4F46E5;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

.create-org-button:before {
  content: "+";
  font-size: 1.25rem;
  font-weight: 400;
  margin-right: 0.25rem;
}

.create-org-form {
  margin-top: 1rem;
}

.create-org-form .form-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.org-name-input {
  background: rgba(39, 39, 42, 0.4);
  border: 1px solid rgba(63, 63, 70, 0.4);
  color: #fff;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  width: 100%;
}

.org-name-input:focus {
  outline: none;
  border-color: #6366F1;
  background: rgba(39, 39, 42, 0.6);
}

.org-name-input::placeholder {
  color: #71717A;
}

.form-buttons {
  display: flex;
  gap: 0.75rem;
}

.create-button,
.cancel-button {
  flex: 1;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.create-button {
  background: #6366F1;
  color: white;
}

.create-button:hover:not(:disabled) {
  background: #4F46E5;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

.cancel-button {
  background: rgba(63, 63, 70, 0.4);
  color: #fff;
}

.cancel-button:hover:not(:disabled) {
  background: rgba(63, 63, 70, 0.6);
  transform: translateY(-1px);
}

/* Organization Switcher */
.org-switcher {
  margin-top: 1.5rem;
  background: rgba(39, 39, 42, 0.3);
  border: 1px solid rgba(63, 63, 70, 0.3);
  border-radius: 8px;
  padding: 1.25rem;
}

.org-switcher h4 {
  color: #A1A1AA;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

.orgs-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.org-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  background: rgba(39, 39, 42, 0.4);
  border: 1px solid rgba(63, 63, 70, 0.4);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.org-item:hover {
  background: rgba(39, 39, 42, 0.5);
  border-color: rgba(63, 63, 70, 0.5);
  transform: translateY(-1px);
}

.org-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.org-name {
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
}

.admin-badge {
  background: rgba(99, 102, 241, 0.2);
  color: #818CF8;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
}

.switch-button {
  background: #6366F1;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.switch-button:hover:not(:disabled) {
  background: #4F46E5;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

.switch-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Danger Zone */
.danger-section {
  margin-top: 2rem;
}

.danger-zone {
  background: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.2);
  border-radius: 0.5rem;
  padding: 1rem;
}

.danger-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.danger-info {
  flex: 1;
}

.danger-info h4 {
  color: #EF4444;
  margin-bottom: 0.25rem;
}

.danger-info p {
  color: #A1A1AA;
  font-size: 0.875rem;
  margin: 0;
}

.delete-all-button {
  background: #EF4444;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.delete-all-button:hover {
  background: #DC2626;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(239, 68, 68, 0.2);
}

.delete-all-button.confirm {
  background: #991B1B;
}

.delete-all-button:disabled {
  background: #3F3F46;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-panel {
    padding: 1rem;
  }

  .invite-button {
    width: auto;
  }

  .user-info {
    flex-wrap: wrap;
  }

  .user-actions {
    width: 100%;
    margin-top: 0.5rem;
    justify-content: flex-end;
  }

  .confirm-remove {
    flex: 1;
    justify-content: flex-end;
  }

  .danger-action {
    flex-direction: column;
    text-align: center;
  }

  .danger-info {
    margin-bottom: 1rem;
  }

  .delete-all-button {
    width: 100%;
  }

  .invite-item {
    flex-direction: column;
    align-items: stretch;
  }

  .invite-actions {
    margin-left: 0;
    margin-top: 0.5rem;
  }

  .confirm-revoke {
    flex: 1;
    justify-content: flex-end;
  }
}

@media (max-width: 480px) {
  .user-actions {
    flex-direction: column;
  }

  .confirm-remove {
    flex-direction: column;
    align-items: stretch;
    text-align: center;
  }

  .confirm-remove span {
    margin-bottom: 0.25rem;
  }

  .role-button,
  .remove-button,
  .confirm-button {
    width: 100%;
  }

  .org-item {
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
  }

  .switch-button {
    width: 100%;
  }

  .invite-meta {
    flex-direction: column;
    gap: 0.25rem;
  }

  .confirm-revoke {
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    padding: 0.5rem;
  }

  .confirm-revoke span {
    margin-bottom: 0.25rem;
  }

  .confirm-button {
    width: 100%;
  }
}

/* Organization Name Display and Edit */
.org-name-display {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.org-name-display p {
  margin: 0;
}

.edit-org-name-button {
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: #A1A1AA;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.edit-org-name-button:hover {
  color: #6366F1;
  background: rgba(99, 102, 241, 0.1);
  transform: translateY(-1px);
}

.edit-icon {
  width: 16px;
  height: 16px;
}

.org-name-edit-form {
  margin-bottom: 1rem;
}

.org-name-edit-form .form-buttons {
  margin-top: 0.75rem;
}

.save-button {
  flex: 1;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  background: #6366F1;
  color: white;
}

.save-button:hover:not(:disabled) {
  background: #4F46E5;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

.save-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.pending-badge {
  background-color: #3B82F6;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  margin-left: 8px;
}

.org-actions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.org-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.org-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.org-name {
  font-weight: 500;
  color: #E5E7EB;
}

.admin-badge {
  background-color: #6366F1;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
}

.switch-button,
.accept-button,
.reject-button,
.revoke-button {
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  color: white;
}

.switch-button {
  background-color: #6366F1;
}

.switch-button:hover {
  background-color: #4F46E5;
  transform: translateY(-1px);
}

.accept-button {
  background-color: #6366F1;
}

.accept-button:hover {
  background-color: #4F46E5;
  transform: translateY(-1px);
}

.reject-button {
  background-color: #4B5563;
}

.reject-button:hover {
  background-color: #374151;
  transform: translateY(-1px);
}

.revoke-button {
  background-color: #DC2626;
}

.revoke-button:hover {
  background-color: #B91C1C;
  transform: translateY(-1px);
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none !important;
}

.delete-confirmation {
  margin-top: 1rem;
}

.confirmation-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.delete-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-button.danger {
  background: var(--error-light);
  color: var(--error);
}

.delete-button.danger:hover {
  background: var(--error);
  color: white;
  transform: translateY(-1px);
}

.delete-confirmation .cancel-button {
  padding: 0.75rem 1.5rem;
  border: 1px solid var(--border);
  border-radius: 8px;
  background: var(--surface);
  color: var(--text);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-confirmation .cancel-button:hover {
  background: var(--hover);
  transform: translateY(-1px);
}

/* Responsive styles */
@media (max-width: 480px) {
  .confirmation-buttons {
    flex-direction: column;
    width: 100%;
  }

  .delete-button,
  .delete-confirmation .cancel-button {
    width: 100%;
  }
} 