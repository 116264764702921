.comments-panel {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100vh;
  background: var(--surface);
  border-left: 1px solid var(--border);
  display: flex;
  flex-direction: column;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
}

.comments-panel.open {
  right: 0;
}

.comments-header {
  padding: 1rem;
  border-bottom: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--surface);
  position: sticky;
  top: 0;
  z-index: 2;
}

.comments-header-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.comments-header h3 {
  margin: 0;
  font-size: 1.1rem;
  color: var(--text-primary);
}

.comments-header .role-title {
  font-size: 0.85rem;
  color: var(--text-secondary);
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
}

.close-button:hover {
  color: var(--text-primary);
}

.comments-list {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  background: var(--background);
}

.comment {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  gap: 0.25rem;
}

.comment.own-comment {
  align-self: flex-end;
}

.comment-bubble {
  background: var(--surface);
  border-radius: 16px;
  padding: 0.75rem 1rem;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.own-comment .comment-bubble {
  background: var(--accent);
  color: white;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
  padding: 0 0.5rem;
}

.comment-actions {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.comment-author {
  font-size: 0.8rem;
  color: var(--text-secondary);
  margin-right: 0.5rem;
}

.own-comment .comment-author {
  color: var(--text-secondary);
}

.comment-time {
  font-size: 0.75rem;
  color: var(--text-secondary);
}

.delete-comment-button {
  background: none;
  border: none;
  padding: 0.25rem;
  color: var(--text-secondary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.delete-comment-button:hover {
  color: var(--error);
  background: var(--error-light);
}

.comment-text {
  color: var(--text-primary);
  font-size: 0.9rem;
  line-height: 1.5;
  white-space: pre-wrap;
}

.own-comment .comment-text {
  color: white;
}

.comment-form {
  padding: 1rem;
  border-top: 1px solid var(--border);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: var(--surface);
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.comment-input-container {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.comment-input {
  width: 100%;
  min-height: 60px;
  max-height: 150px;
  padding: 0.75rem;
  border: 1px solid var(--border);
  border-radius: 20px;
  background: var(--surface-alt);
  color: var(--text-primary);
  resize: vertical;
  box-sizing: border-box;
  font-size: 0.9rem;
  line-height: 1.5;
  font-family: inherit;
}

.comment-input:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 2px var(--accent-light);
}

.submit-button {
  align-self: flex-end;
  padding: 0.5rem 1rem;
  background: var(--accent);
  color: white;
  border: none;
  border-radius: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background: var(--accent-dark);
}

@media (max-width: 768px) {
  .comments-panel {
    width: 100%;
    right: -100%;
  }
} 