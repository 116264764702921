.get-started-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.get-started-modal {
  background: var(--surface);
  border-radius: 12px;
  padding: 2rem;
  width: 90%;
  max-width: 570px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border);
}

.modal-header h2 {
  margin: 0;
  color: var(--text-primary);
  font-size: 1.5rem;
  font-weight: 600;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.close-button:hover {
  background-color: var(--surface-hover);
}

.welcome-section {
  text-align: center;
  margin-bottom: 2rem;
}

.welcome-section h3 {
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.welcome-section p {
  color: var(--text-secondary);
  margin: 0;
}

.modal-content {
  width: 100%;
}

.options-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
}

.option-card {
  background: var(--surface-hover);
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid var(--border);
  position: relative;
}

.option-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.step-label {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--accent);
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.option-icon {
  font-size: 2.5rem;
  margin: 1.5rem 0 1rem;
}

.option-card h4 {
  color: var(--text-primary);
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
}

.option-card p {
  color: var(--text-secondary);
  margin: 0 0 1.5rem 0;
  font-size: 0.9rem;
  line-height: 1.5;
}

.primary-button {
  background-color: #34D399;  /* Green color */
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
}

.primary-button:hover:not(.disabled) {
  background-color: #10B981;  /* Darker green on hover */
  transform: translateY(-1px);
}

.primary-button.disabled {
  background-color: #9CA3AF;  /* Grey color */
  cursor: not-allowed;
  opacity: 0.7;
}

.primary-button.disabled:hover {
  transform: none;
  background-color: #9CA3AF;
} 