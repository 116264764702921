.compensation-framework {
  padding: 2rem;
  color: var(--text-primary);
}

.compensation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.compensation-header-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.compensation-header-title {
  margin: 0;
  font-size: 1.25rem;
}

.compensation-currency-selector {
  display: flex;
  align-items: center;
}

.compensation-currency-select {
  height: 38px;
  padding: 0 1.25rem;
  border: 1px solid var(--border);
  border-radius: 6px;
  background-color: var(--surface-alt);
  color: var(--text-primary);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2.5 4.5L6 8L9.5 4.5' stroke='%23a1a1aa' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  padding-right: 2rem;
}

.compensation-header-actions {
  display: flex;
  gap: 0.75rem;
}

.compensation-action-button {
  height: 40px;
  padding: 0 1.25rem;
  border: 1px solid var(--border);
  border-radius: 6px;
  background: var(--surface);
  color: var(--text-primary);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
}

.compensation-currency-select:hover,
.compensation-action-button:hover {
  background-color: var(--surface-hover);
  border-color: var(--accent);
}

.compensation-currency-select:focus,
.compensation-action-button:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 1px var(--accent);
}

.framework-section {
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  overflow: hidden;
}

.function-tabs-wrapper {
  position: relative;
  margin-bottom: 1.5rem;
}

.function-tabs-container {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 4px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.function-tabs {
  display: inline-flex;
  gap: 0.5rem;
  padding: 4px;
  white-space: nowrap;
}

.function-tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border: 1px solid var(--border);
  border-radius: 20px;
  background: var(--surface);
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
  flex: 0 0 auto;
}

.function-tab:hover {
  background: var(--surface-alt);
}

.function-tab.active {
  background: var(--accent);
  color: white;
  border-color: var(--accent);
}

.compensation-matrix {
  overflow-x: auto;
}

.compensation-matrix table {
  width: 100%;
  border-collapse: collapse;
}

.compensation-matrix th,
.compensation-matrix td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid var(--border);
}

.compensation-matrix th {
  font-weight: 500;
  color: var(--text-secondary);
  background: var(--surface-alt);
}

.compensation-matrix input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border);
  border-radius: 4px;
  background: var(--surface);
  color: var(--text-primary);
}

.compensation-matrix input:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 1px var(--accent);
}

.tiers-grid {
  display: grid;
  gap: 1rem;
  max-width: 400px;
}

.tier-row {
  display: grid;
  grid-template-columns: 1fr 120px;
  gap: 1rem;
  align-items: center;
}

.tier-row input {
  padding: 0.5rem;
  border: 1px solid var(--border);
  border-radius: 4px;
  background: var(--surface);
  color: var(--text-primary);
}

.location-tiers-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
}

.location-assignments {
  border-left: 1px solid var(--border);
  padding-left: 2rem;
}

.location-search {
  margin-bottom: 1rem;
  max-width: 300px;
}

.location-search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border);
  border-radius: 6px;
  background: var(--surface);
  color: var(--text-primary);
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.location-search-input:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 1px var(--accent);
  background: var(--surface-hover);
}

.location-search-input::placeholder {
  color: var(--text-tertiary);
}

.location-assignments h3 {
  margin: 0 0 1rem 0;
  font-size: 1rem;
  color: var(--text-secondary);
}

.location-grid {
  display: grid;
  gap: 0.75rem;
  max-height: 400px;
  overflow-y: auto;
}

.location-row {
  display: grid;
  grid-template-columns: 1fr 160px;
  gap: 1rem;
  align-items: center;
}

.location-name {
  font-size: 0.875rem;
}

.location-row select {
  height: 36px;
  padding: 0 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--border);
  background: var(--surface);
  color: var(--text-primary);
  cursor: pointer;
  font-size: 0.875rem;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2.5 4.5L6 8L9.5 4.5' stroke='%23a1a1aa' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  padding-right: 2rem;
}

@media (max-width: 1024px) {
  .location-tiers-container {
    grid-template-columns: 1fr;
  }

  .location-assignments {
    border-left: none;
    border-top: 1px solid var(--border);
    padding-left: 0;
    padding-top: 2rem;
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .compensation-framework {
    padding: 1rem;
  }

  .framework-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .compensation-matrix {
    font-size: 0.875rem;
  }
}