.landing-page {
  min-height: 100vh;
  background-color: rgba(17, 24, 39, 0.8);;
  color: var(--text-primary);
}

.landing-page.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: var(--accent);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.landing-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 0;
  background: rgba(17, 24, 39, 0.8);
  backdrop-filter: blur(8px);
  z-index: 100;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.landing-logo-image {
  height: 40px;
  width: 125%;
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.login-button {
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;
}

.login-button:hover {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-1px);
}

.login-button:active {
  transform: translateY(0);
}

.header-cta-button {
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  background: #6366f1;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  letter-spacing: 0.025em;
  margin-right: 30px
}

.header-cta-button:hover {
  background: #4f46e5;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

.header-cta-button:active {
  transform: translateY(0);
}

.hero-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 8rem 2rem 6rem;
  display: flex;
  align-items: center;
  gap: 4rem;
  color: white;
}

.hero-content {
  flex: 1;
  max-width: 600px;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-preview {
  width: 130%;
  max-width: 2000px;
  height: auto;
  filter: drop-shadow(0 50px 70px rgba(255, 255, 255, 0.1));
  margin-left: 0
}

.hero-content h1 {
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 2rem;
  color: white;
}

.gradient-text {
  background: linear-gradient(90deg, #6366f1, #a855f7);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: #94a3b8;
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

.cta-button {
  padding: 1rem 2.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
  background: #6366f1;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cta-button:hover {
  background: #4f46e5;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

.features-section {
  background: rgba(255, 255, 255, 0.896);
  padding: 6rem 0;
  position: relative;
  overflow: hidden;
}

.features-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(99, 102, 241, 0.2),
    transparent
  );
}

.features-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.features-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4rem;
}

.features-header h2 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.1;
  color: #1e293b;
  margin: 0;
  flex: 1;
}

.features-header p {
  font-size: 1.25rem;
  color: #64748b;
  line-height: 1.6;
  margin: 0;
  padding-left: 2rem;
  flex: 1;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.feature-card {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.feature-card:hover {
  background: white;
  transform: translateY(-2px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.feature-content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0 0 1rem 0;
}

.feature-content p {
  font-size: 1rem;
  color: #64748b;
  line-height: 1.6;
  margin: 0;
}

@media (max-width: 1024px) {
  .hero-section {
    flex-direction: column;
    padding: 6rem 2rem 4rem;
    text-align: center;
  }

  .hero-content {
    max-width: 100%;
  }

  .hero-content h1 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }

  .hero-subtitle {
    font-size: 1.125rem;
    margin-bottom: 2rem;
  }

  .hero-image {
    margin-top: 2rem;
    padding: 0 1rem;
  }

  .dashboard-preview {
    width: 100%;
    margin-left: 0;
    border-radius: 12px;
  }

  .features-header {
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
  }

  .features-header h2 {
    font-size: 2.5rem;
    margin-bottom: 0;
  }

  .features-header p {
    padding-left: 0;
    font-size: 1.125rem;
  }

  .feature-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 0 1rem;
  }

  .feature-card {
    padding: 1.5rem;
  }

  .header-buttons {
    gap: 0.75rem;
  }

  .login-button,
  .header-cta-button {
    padding: 0.75rem 1.25rem;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: manipulation;
  }
}

@media (max-width: 640px) {
  .landing-header {
    position: static;
    background: rgba(17, 24, 39, 0.95);
    padding: 1rem 0;
    backdrop-filter: none;
  }

  .header-content {
    padding: 0 1rem;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  .header-left {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .logo {
    display: flex;
    align-items: center;
    margin-left: 20px
  }

  .landing-logo-image {
    height: 32px;
    width: auto;
  }

  .header-buttons {
    justify-self: end;
  }

  .login-button {
    width: auto;
    margin: 0;
    min-width: 100px;
  }

  .header-cta-button {
    display: none;
  }

  .hero-section {
    padding: 2rem 1rem 3rem;
  }

  .hero-content h1 {
    font-size: 2.25rem;
    margin-bottom: 1rem;
  }

  .hero-subtitle {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    br {
      display: none;
    }
  }

  .cta-button {
    width: 100%;
    padding: 0.875rem 1.5rem;
    min-height: 44px;
  }

  .features-section {
    padding: 3rem 0;
  }

  .features-content {
    padding: 0 1rem;
  }

  .features-header h2 {
    font-size: 2rem;
    br {
      display: none;
    }
  }

  .features-header p {
    font-size: 1rem;
    br {
      display: none;
    }
  }

  .feature-card {
    padding: 1.25rem;
  }

  .feature-content h3 {
    font-size: 1.25rem;
  }

  .feature-content p {
    font-size: 0.875rem;
  }

  .header-buttons {
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
  }

  .login-button,
  .header-cta-button {
    width: 60%;
    margin: 0;
  }
} 