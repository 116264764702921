.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-panel {
  width: 90%;
  max-width: 500px;
  background: var(--surface);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  position: relative;
  z-index: 1001;
}

.modal-title {
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--border);
}

.presentation-currency {
  margin-bottom: 2rem;
  padding: 1rem;
  background: var(--surface-hover);
  border-radius: 6px;
  border: 1px solid var(--border);
}

.presentation-currency label {
  display: block;
  color: var(--text-primary);
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.currency-select {
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--border);
  background: var(--surface);
  color: var(--text-primary);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.helper-text {
  color: var(--text-secondary);
  font-size: 0.75rem;
  margin: 0;
}

.rates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.rate-input-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.rate-input-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rate-input-wrapper input {
  flex: 1;
  padding: 8px;
  border: 1px solid var(--border);
  border-radius: 4px;
  font-size: 14px;
}

.rate-input-wrapper input.error {
  border-color: var(--error);
}

.currency-label {
  color: var(--text-secondary);
  font-size: 14px;
  min-width: 40px;
}

.error-message {
  color: var(--error);
  font-size: 12px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--border);
}

.cancel-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 1px solid var(--border);
  background: var(--surface);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-button:hover {
  background: var(--surface-hover);
  color: var(--text-primary);
}

.submit-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  background: var(--accent);
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-button:hover {
  background: var(--accent-dark);
}

@media (max-width: 768px) {
  .rates-grid {
    grid-template-columns: 1fr;
  }

  .modal-actions {
    flex-direction: column-reverse;
  }

  .cancel-button,
  .submit-button {
    width: 100%;
  }
} 