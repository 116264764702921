.commission-view {
    padding: 2rem;
}

.commission-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.commission-header h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
}

.structure-button {
    padding: 0.5rem 1rem;
    background: var(--accent-light);
    color: var(--accent);
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.structure-button:hover {
    background: var(--accent);
    color: white;
}

/* Loading State */
.loading-message {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
    font-size: 1rem;
}

.no-sales-members {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
    font-size: 1rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
    margin-top: 1rem;
}

/* Sales Person Section */
.sales-person-section {
    margin-bottom: 1rem;
    border: 1px solid var(--border);
    border-radius: 8px;
    overflow: hidden;
    background: var(--surface);
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

.sales-person-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    background: var(--surface);
    border-radius: 8px;
    transition: background-color 0.2s;
}

.sales-person-header:hover {
    background: var(--surface-hover);
}

.sales-person-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.sales-person-info h4 {
    margin: 0;
    font-size: 1.1rem;
    color: var(--text-primary);
}

.sales-person-stats {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.stat-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.75rem;
    border-radius: 16px;
    font-size: 0.875rem;
    color: var(--text-secondary);
    background: var(--surface-hover);
}

.stat-badge select {
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 4px;
    color: var(--text-primary);
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    margin-left: 0.5rem;
}

.sales-person-content {
    padding: 1.5rem;
    border-top: 1px solid var(--border);
}

/* Quarters Header */
.quarters-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.quarters-header h3 {
    margin: 0;
    font-size: 1.2rem;
    color: var(--text-primary);
}

.add-quarter-button {
    padding: 0.5rem 1rem;
    background: var(--success-light);
    color: var(--success);
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.add-quarter-button:hover {
    background: var(--success);
    color: white;
}

.no-quarters-message {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
    font-size: 1rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
}

/* Quarter Section */
.quarter-section {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 8px;
}

.quarter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.quarter-header h3 {
    margin: 0;
    font-size: 1.2rem;
    color: var(--text-primary);
}

.delete-quarter-button {
    padding: 0.5rem 1rem;
    background: var(--error-light);
    color: var(--error);
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.delete-quarter-button:hover {
    background: var(--error);
    color: white;
}

/* Add Quarter Modal */
.add-quarter-modal {
    background: var(--surface);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    color: var(--text);
}

.add-quarter-modal .modal-content {
    padding: 20px 0;
}

.add-quarter-modal .year-quarter-selectors {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.add-quarter-modal .year-quarter-selectors .form-group {
    flex: 1;
}

.add-quarter-modal select {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--border);
    border-radius: 4px;
    background-color: var(--surface-elevated);
    color: var(--text);
    font-size: 14px;
}

.add-quarter-modal select:focus {
    border-color: var(--primary);
    outline: none;
}

.add-quarter-modal label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: var(--text-secondary);
}

.add-quarter-modal .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 20px;
    border-top: 1px solid var(--border);
}

.add-quarter-modal button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 500;
}

.add-quarter-modal .save-button {
    background-color: var(--primary);
    color: white;
}

.add-quarter-modal .save-button:hover {
    background-color: var(--primary-dark);
}

.add-quarter-modal .cancel-button {
    background-color: var(--surface-elevated);
    color: var(--text);
    border: 1px solid var(--border);
}

.add-quarter-modal .cancel-button:hover {
    background-color: var(--surface);
}

.add-quarter-modal .close-button {
    position: absolute;
    right: 15px;
    top: 15px;
    background: none;
    border: none;
    font-size: 20px;
    color: var(--text-secondary);
    cursor: pointer;
}

.add-quarter-modal .close-button:hover {
    color: var(--text);
}

/* Role Selector */
.role-selector {
    margin-bottom: 2rem;
}

.role-selector-controls {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.role-selector select,
.role-selector input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid var(--border);
    border-radius: 4px;
    background: var(--surface);
    color: var(--text-primary);
    font-size: 0.875rem;
}

.role-selector select:focus,
.role-selector input:focus {
    outline: none;
    border-color: var(--accent);
    box-shadow: 0 0 0 1px var(--accent);
}

.add-role-button {
    padding: 0.5rem 1rem;
    background: var(--success-light);
    color: var(--success);
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    white-space: nowrap;
}

.add-role-button:hover {
    background: var(--success);
    color: white;
}

.add-role-form {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.add-role-form input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid var(--border);
    border-radius: 4px;
    background: var(--surface);
    color: var(--text-primary);
    font-size: 0.875rem;
}

.add-role-form input:focus {
    outline: none;
    border-color: var(--accent);
    box-shadow: 0 0 0 1px var(--accent);
}

.add-role-form button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.add-role-form .save-button {
    background: var(--success-light);
    color: var(--success);
}

.add-role-form .save-button:hover {
    background: var(--success);
    color: white;
}

.add-role-form .cancel-button {
    background: var(--error-light);
    color: var(--error);
}

.add-role-form .cancel-button:hover {
    background: var(--error);
    color: white;
}

/* Commission Form */
.commission-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    color: var(--text-secondary);
    font-size: 0.875rem;
}

.form-group input,
.form-group select {
    padding: 0.5rem;
    border: 1px solid var(--border);
    border-radius: 4px;
    background: var(--surface);
    color: var(--text-primary);
    font-size: 0.875rem;
}

.form-group input:focus,
.form-group select:focus {
    outline: none;
    border-color: var(--accent);
    box-shadow: 0 0 0 1px var(--accent);
}

/* Monthly Attainment Section */
.monthly-attainment {
    margin-top: 1.5rem;
}

.monthly-attainment h4 {
    margin-bottom: 1rem;
    color: var(--text-primary);
}

.attainment-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.month-input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

/* Progress Indicators */
.progress-bar {
    width: 100%;
    height: 8px;
    background: var(--surface-hover);
    border-radius: 4px;
    overflow: hidden;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.progress-fill {
    height: 100%;
    background: var(--accent);
    transition: width 0.3s ease;
}

.progress-fill.success {
    background: var(--success);
}

.progress-fill.warning {
    background: var(--warning);
}

.progress-fill.error {
    background: var(--error);
}

/* Expand Button */
.expand-button {
    background: none;
    border: none;
    color: var(--text-secondary);
    cursor: pointer;
    font-size: 1rem;
    padding: 0.5rem;
    transition: transform 0.2s ease;
}

.expand-button:hover {
    color: var(--text-primary);
}

/* Responsive Design */
@media (max-width: 768px) {
    .commission-view {
        padding: 1rem;
    }

    .commission-form {
        grid-template-columns: 1fr;
    }

    .summary-stats {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .attainment-grid {
        grid-template-columns: 1fr;
    }

    .sales-person-stats {
        flex-direction: column;
        align-items: flex-start;
    }

    .stat-badge {
        width: 100%;
    }

    .role-selector-controls {
        flex-direction: column;
    }

    .add-role-form {
        flex-direction: column;
    }

    .add-quarter-modal {
        padding: 1rem;
        width: 95%;
    }
} 

/* Commission Structure Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.commission-structure-modal {
    background: var(--surface);
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 2rem;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: var(--text-primary);
}

.close-button {
    background: none;
    border: none;
    color: var(--text-secondary);
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
}

.close-button:hover {
    color: var(--text-primary);
}

.structure-description {
    margin-bottom: 2rem;
    color: var(--text-secondary);
}

.structure-description p {
    margin: 0.5rem 0;
}

.structure-description ul {
    margin: 0.5rem 0;
    padding-left: 1.5rem;
}

.thresholds-container {
    margin-bottom: 2rem;
}

.thresholds-container h3 {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
    color: var(--text-primary);
}

.threshold-row {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    margin-bottom: 1rem;
    padding: 1rem;
    background: var(--surface-hover);
    border-radius: 4px;
}

.threshold-inputs {
    display: flex;
    gap: 1rem;
    flex: 1;
}

.remove-threshold-button {
    padding: 0.5rem 1rem;
    background: var(--error-light);
    color: var(--error);
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.remove-threshold-button:hover:not(:disabled) {
    background: var(--error);
    color: white;
}

.remove-threshold-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.add-threshold-button {
    padding: 0.5rem 1rem;
    background: var(--success-light);
    color: var(--success);
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 1rem;
}

.add-threshold-button:hover {
    background: var(--success);
    color: white;
}

.default-payout {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid var(--border);
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid var(--border);
}

.modal-footer button {
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.modal-footer .cancel-button {
    background: var(--error-light);
    color: var(--error);
}

.modal-footer .cancel-button:hover {
    background: var(--error);
    color: white;
}

.modal-footer .save-button {
    background: var(--success-light);
    color: var(--success);
}

.modal-footer .save-button:hover {
    background: var(--success);
    color: white;
}

/* Summary Stats */
.summary-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    background: var(--surface);
    border: 1px solid var(--border);
    border-radius: 6px;
}

.stat-item label {
    font-size: 0.9rem;
    color: var(--text-secondary);
    margin-bottom: 0.5rem;
}

.stat-item span {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
}

/* Responsive Design */
@media (max-width: 768px) {
    /* ... keep existing mobile styles ... */

    .threshold-row {
        flex-direction: column;
        align-items: stretch;
    }

    .threshold-inputs {
        flex-direction: column;
    }

    .commission-structure-modal {
        padding: 1rem;
        width: 95%;
    }
} 

.no-roles-message {
    padding: 1rem;
    background: var(--surface-hover);
    border-radius: 4px;
    color: var(--text-secondary);
    font-size: 0.875rem;
    text-align: center;
    width: 100%;
}

.commission-controls {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.toggle-leavers-button {
    padding: 0.5rem 1rem;
    background: var(--surface);
    color: var(--text);
    border: 1px solid var(--border);
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.toggle-leavers-button:hover {
    background: var(--hover);
}

.toggle-leavers-button.active {
    background: var(--primary);
    color: white;
    border-color: var(--primary);
}