.analysis {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}

.analysis-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.filter-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.org-filter {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
}

/* Distribution Charts Section */
.distribution-charts {
  background: var(--surface);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1400px;
  height: 350px;
  border: 1px solid var(--border);
  margin-left: -20px;
  margin-right: auto;
}

.distribution-charts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  height: 100%;
}

.distribution-charts h3 {
  margin: 0;
  padding-bottom: 10px;
  color: var(--text-primary);
  font-size: 1rem;
  text-align: center;
}

/* Chart Wrappers */
.chart-wrapper {
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 0px;
  width: 98.5%;
  max-width: 1300px;
  margin-left: -20px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.chart-wrapper h3 {
  margin: 0;
  padding-bottom: 20px;
  color: var(--text-primary);
  font-size: 1rem;
  text-align: center;
}

/* Common chart container styles */
.chart-container {
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.chart-container > div {
  width: 100% !important;
  height: 100% !important;
}
 