/* Theme Variables */
:root {
  /* Base colors */
  --background: #17181d;
  --surface: #1d1e23;
  --surface-hover: #2b2d33;
  --border: #363940;
  
  /* Text colors */
  --text-primary: #fff;
  --text-secondary: #a1a1aa;
  
  /* Accent colors */
  --accent: #5e6ad2;
  --accent-hover: #6e7ae2;
  --accent-light: rgba(94, 106, 210, 0.1);
  
  /* Status colors */
  --success: #2dd4bf;
  --success-light: rgba(45, 212, 191, 0.1);
  --warning: #f59e0b;
  --warning-light: rgba(245, 158, 11, 0.1);
  --error: #f43f5e;
  --error-light: rgba(244, 63, 94, 0.1);
}

.leavers-page {
  padding: 2rem;
  color: var(--text-primary);
  max-width: 1400px;
  margin: 0 auto;
}

.leavers-page h1 {
  color: var(--text-primary);
  margin-bottom: 2rem;
  font-size: 1.75rem;
}

.leavers-section {
  margin-bottom: 3rem;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 1.5rem;
}

.leavers-section h2 {
  color: var(--text-primary);
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  border-bottom: 1px solid var(--border);
  padding-bottom: 0.75rem;
}

.leaver-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 0.75rem;
}

.leaver-card {
  background: var(--surface-hover);
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
}

.leaver-card:hover {
  border-color: var(--accent);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-1px);
}

.leaver-card:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Add a subtle indicator that the card is clickable */
.leaver-card::after {
  content: '';
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 6px;
  height: 6px;
  border-right: 2px solid var(--text-secondary);
  border-bottom: 2px solid var(--text-secondary);
  transform: rotate(-45deg);
  opacity: 0.5;
  transition: all 0.2s ease;
}

.leaver-card:hover::after {
  opacity: 1;
  border-color: var(--accent);
}

.leaver-card-name {
  margin-bottom: 0.5rem;
}

.leaver-card-name h3 {
  margin: 0;
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
}

.leaver-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border);
}

.header-content {
  flex: 1;
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.leaver-title, .leaver-team {
  color: var(--text-secondary);
  font-size: 0.875rem;
  font-weight: normal;
}

.leaver-team {
  position: relative;
  padding-left: 0.75rem;
}

.leaver-team::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--border);
}

.leaver-card-body {
  font-size: 0.813rem;
}

.leaver-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.125rem 0;
}

.leaver-info .label {
  color: var(--text-secondary);
  font-weight: 500;
}

.leaver-info span:not(.label) {
  color: var(--text-primary);
}

.leaver-info.highlight {
  background: var(--warning-light);
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  margin-top: 0.75rem;
}

.leaver-info.highlight .label {
  color: var(--warning);
}

.leaver-info.highlight span:not(.label) {
  color: var(--warning);
  font-weight: 500;
}

.no-data {
  text-align: center;
  color: var(--text-secondary);
  padding: 2rem;
  background: var(--surface-hover);
  border-radius: 8px;
  font-style: italic;
  border: 1px dashed var(--border);
}

.page-header {
  margin-bottom: 2rem;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 1.5rem;
}

.page-header h1 {
  margin: 0 0 1.5rem 0;
}

.sort-controls {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.sort-controls button {
  padding: 0.75rem 1rem;
  background: var(--surface-hover);
  border: 1px solid var(--border);
  border-radius: 4px;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.875rem;
}

.sort-controls button:hover {
  border-color: var(--accent);
  background: var(--surface-hover);
}

.sort-controls button.active {
  background: var(--accent-light);
  color: var(--accent);
  border-color: var(--accent);
}

/* Status Badges */
.status-badge {
  padding: 0.125rem 0.5rem;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 500;
}

.status-badge.pending {
  background: var(--warning-light);
  color: var(--warning);
}

.status-badge.completed {
  background: var(--success-light);
  color: var(--success);
}

.status-badge.temporary {
  background-color: var(--warning-light);
  color: var(--warning);
}

/* Update leaver card header for badge */
.leaver-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.header-content {
  flex: 1;
}

.backfill-actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 1rem;
  padding-top: 0.75rem;
  border-top: 1px solid var(--border);
}

.backfill-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
  flex: 1;
}

.backfill-button.needed {
  background: var(--warning-light);
  color: var(--warning);
}

.backfill-button.needed:hover {
  background: var(--warning);
  color: white;
}

.backfill-button.not-needed {
  background: var(--success-light);
  color: var(--success);
}

.backfill-button.not-needed:hover {
  background: var(--success);
  color: white;
}

.backfill-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.status-indicator {
  flex: 1;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
}

.status-indicator.needed {
  background: var(--warning);
  color: white;
}

.status-indicator.not-needed {
  background: var(--success);
  color: white;
}

.change-status-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid var(--border);
  background: var(--surface-hover);
  color: var(--text-secondary);
}

.change-status-button:hover {
  background: var(--surface);
  border-color: var(--accent);
  color: var(--text-primary);
}

.leave-type {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin-top: 4px;
}
  